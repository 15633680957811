import { SoulcircusProduct } from "@cruncho/cruncho-shared-types";
import Button from "@mui/material/Button";
import { useCallback, useEffect, useState } from "react";
import { api } from "services/api";
import { CreateProductDialog } from "./CreateProductDialog";
import { EditProductDialog } from "./EditProductDialog";
import { ProductList } from "./ProductList";

export function ProductView() {
	const [products, setProducts] = useState<SoulcircusProduct[]>([]);

	const fecthAllProducts = useCallback(async () => {
		const all_products = await api.product.getAll();

		setProducts(all_products);
	}, []);

	const [openEdit, setOpenEdit] = useState(false);
	const [editingProduct, setEditingProduct] = useState<{
		product?: SoulcircusProduct;
	}>();

	/**
	 * Triggered when a format is selected for edition
	 */
	const handleEditProduct = (product?: SoulcircusProduct) => {
		setEditingProduct({ product });
		setOpenEdit(true);
	};

	/**
	 * Triggered when creating a format
	 */
	const handleAddProduct = () => {
		setEditingProduct({
			product: undefined,
		});
		setOpenEdit(true);
	};

	/**
	 * Triggered after a format was saved in a modal
	 */
	const handleSaveProduct = async () => {
		// better job could be done here by checking which format has changed
		// and avoid requesting everything back again

		await fecthAllProducts();
	};

	/**
	 * Triggered after a format was created in a modal
	 */
	const handleCreateProduct = async () => {
		// better job could be done here by checking the created format and append it to the correct list,
		// and avoid requesting everything back again

		await fecthAllProducts();
	};

	/**
	 * Triggered when clicking on the "delete" button on an element of the mappings list
	 */
	const deleteProduct = async (product?: SoulcircusProduct) => {
		if (product) {
			await api.product.delete(product._id);

			const newProducts = products.filter(
				(productItem) => productItem._id !== product._id,
			);
			setProducts(newProducts);
		}
	};

	useEffect(() => {
		fecthAllProducts();
	}, [fecthAllProducts]);

	return (
		<div className="p-4">
			<Button
				variant="contained"
				color="primary"
				onClick={() => {
					handleAddProduct();
				}}
				className="mb-4"
			>
				Create new Product
			</Button>
			<ProductList
				products={products}
				onProductDeleteSelected={deleteProduct}
				onProductEditSelected={handleEditProduct}
			/>
			{/* if the current product is undefined, it means we are in creation mode else, it's edit mode */}
			{editingProduct?.product ? (
				<EditProductDialog
					open={openEdit}
					product={editingProduct.product}
					onClose={() => setOpenEdit(false)}
					onSave={async () => {
						await handleSaveProduct();
						setOpenEdit(false);
						setEditingProduct(undefined);
					}}
				/>
			) : (
				<CreateProductDialog
					open={openEdit}
					onClose={() => setOpenEdit(false)}
					onSave={async () => {
						await handleCreateProduct();
						setOpenEdit(false);
						setEditingProduct(undefined);
					}}
				/>
			)}
		</div>
	);
}
