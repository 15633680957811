import { Practitioner } from "@cruncho/cruncho-shared-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import { ExperienceSectionItem } from "./ExperienceSectionItem";

interface ExperienceSectionProps {
	practitionerData: Practitioner;
	practitionerId: string;
}

export function ExperienceSection({
	practitionerData,
	practitionerId,
}: ExperienceSectionProps) {
	return (
		<Card>
			<CardHeader title="Experience section" />
			<CardContent>
				<List>
					{practitionerData.experienceSection &&
						practitionerData.experienceSection.map((_, index) => (
							<>
								<ExperienceSectionItem
									index={index}
									experienceSectionData={practitionerData.experienceSection!}
									practitionerId={practitionerId}
									key={`experience_${index}`}
								/>
								<Divider
									style={{
										marginTop: 15,
										marginBottom: 20,
									}}
								/>
							</>
						))}
				</List>
			</CardContent>
		</Card>
	);
}
