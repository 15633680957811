import { ChildCategory } from "@cruncho/cruncho-shared-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import groupBy from "lodash/groupBy";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { api } from "services/api";
import { DragableCategoryListItem } from "./DragableListItem";

function reorder<T>(list: T[], startIndex: number, endIndex: number) {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
}

function _InnerList(props: { categories: ChildCategory[] }) {
	return (
		<>
			{props.categories.map((category, index) => (
				<DragableCategoryListItem
					key={category.slug}
					category={category}
					index={index}
				/>
			))}
		</>
	);
}
const InnerList = React.memo(_InnerList);

interface OrderL2CategoriesDialogProps {
	/**
	 * Is the dialog opened?
	 */
	open: boolean;

	/**
	 * Callback when closing the dialog
	 * @param shouldRefresh - If `true`, the order was changed and you may need to refresh the L2 categories list.
	 */
	onClose(shouldRefresh?: boolean): void;

	/**
	 * Categories to reorder
	 */
	categories: ChildCategory[];
}

/**
 * Dialog to order L2 categories, grouped by their parent slug.
 */
export function OrderL2CategoriesDialog({
	open,
	onClose,
	categories,
}: OrderL2CategoriesDialogProps) {
	const [groupedCategories, setGroupedCategories] = useState<
		Record<string, ChildCategory[]>
	>({});
	const [isSaving, setIsSaving] = useState(false);

	useEffect(() => {
		setGroupedCategories(groupBy(categories, (category) => category.parentSlug));
	}, [categories]);

	const handleSave = () => {
		setIsSaving(true);
		Promise.allSettled(
			Object.values(groupedCategories).flatMap((categoriesList) =>
				categoriesList.map((category, index) =>
					api.category.l2.update(category.slug, { ...category, order: index }),
				),
			),
		).then((results) => {
			setIsSaving(false);
			results.forEach((result) => {
				if (result.status === "rejected") {
					console.error(result.reason);
				}
			});
			onClose(true);
		});
	};

	return (
		<Dialog open={open} onClose={() => onClose()} fullWidth>
			<DialogTitle>Reorder L2 categories</DialogTitle>

			<DialogContent dividers style={{ overflowY: "unset" }}>
				{Object.entries(groupedCategories).map(([key, categoriesList]) => (
					<DragDropContext
						key={key}
						onDragEnd={(result) => {
							// Dropped outside the list
							if (!result.destination) {
								return;
							}

							const items = reorder(
								categoriesList,
								result.source.index,
								result.destination.index,
							);

							setGroupedCategories((prevState) => ({ ...prevState, [key]: items }));
						}}
					>
						<Typography variant="h6">{key}</Typography>
						<div className="mb-4">
							<Droppable droppableId="categories">
								{(provided) => (
									<Grid
										ref={provided.innerRef}
										{...provided.droppableProps}
										container
										spacing={1}
									>
										<InnerList categories={categoriesList} />

										{provided.placeholder}
									</Grid>
								)}
							</Droppable>
						</div>
					</DragDropContext>
				))}
			</DialogContent>

			<DialogActions>
				<Button
					color="secondary"
					variant="outlined"
					onClick={() => {
						setGroupedCategories(
							groupBy(categories, (category) => category.parentSlug),
						);
						onClose();
					}}
					disabled={isSaving}
				>
					Cancel
				</Button>

				<Button
					color="primary"
					variant="contained"
					onClick={handleSave}
					disabled={isSaving}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
