import {
	AvailableBugCategory,
	AvailableBugProduct,
	AvailableBugStatus,
	BugReport,
	BUG_REPORT_CATEGORIES,
	BUG_REPORT_PRODUCTS,
	BUG_REPORT_STATUS,
} from "@cruncho/cruncho-shared-types";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

/**
 * Helper to sort bug reports by created date. Oldest date on top.
 * Pass it to a .sort function
 */
const sortBugReports = (bugA: BugReport, bugB: BugReport) =>
	new Date(bugA.createdAt).getTime() - new Date(bugB.createdAt).getTime();

type BugReportsListProps = {
	/**
	 * The list of bug reports to display
	 */
	bugReportsList: Array<BugReport>;
	/**
	 * Triggered when clicking on a bug report
	 */
	onBugReportSelected: (bugReport: BugReport) => void;
	/**
	 * Triggered when choosing a bug report status filter
	 */
	onStatusSelected: (status: AvailableBugStatus) => void;
	/**
	 * Currently selected bug report. We want a null value and not an undefined one to have a
	 * controlled input field
	 */
	selectedBugReport: BugReport | null;
	/**
	 * Currently selected status
	 */
	selectedStatus: AvailableBugStatus;
};

/**
 * A listing of the visible bug reports, with the option to filter by status and category,
 * and a search bar
 */
export function BugReportsList({
	bugReportsList,
	onBugReportSelected,
	onStatusSelected,
	selectedBugReport,
	selectedStatus,
}: BugReportsListProps) {
	/**
	 * To filter the bug reports by category. Only one category can be selected at the same time
	 */
	const [selectedCategory, setSelectedCategory] = useState<
		AvailableBugCategory | "All"
	>("All");
	/**
	 * To filter the bug reports by product. Only one produt can be selected at the same time
	 */
	const [selectedProduct, setSelectedProduct] = useState<
		AvailableBugProduct | "All"
	>("All");
	/**
	 * A string used to filter the displayed bug reports
	 */
	const [searchQuery, setSearchQuery] = useState<string>("");

	/**
	 * The subset of bug reports kept while filtering
	 */
	const [filteredBugReports, setFilteredBugReports] = useState<Array<BugReport>>(
		[],
	);

	/**
	 * Search by api, cruncho_slug and api_category_id
	 */
	useEffect(() => {
		/**
		 * Check if a bug report categories include the selected category.
		 * If selected category is "All", return true.
		 *
		 * @param bugReport Checked bug report
		 * @returns true if the given bug report should be kept by category filter, else false
		 */
		const correctCategory = (bugReport: BugReport) =>
			selectedCategory === "All" ||
			bugReport.categories.includes(selectedCategory);
		/**
		 * Check if a bug report product is the one selected.
		 * If selected product is "All", return true.
		 *
		 * @param bugReport Checked bug report
		 * @returns true if the given bug report should be kept by product filter, else false
		 */
		const correctProduct = (bugReport: BugReport) =>
			selectedProduct === "All" || bugReport.product === selectedProduct;
		/**
		 * Check if bug report description, email or reportPageUrl contains the search query
		 * @param bugReport Checked bug report
		 * @returns true if the given bug report should be kept by query filter, else false
		 */
		const includesSearchQuery = (bugReport: BugReport) => {
			const terms = searchQuery.split(" ");
			return terms.every(
				(term) =>
					bugReport.description?.includes(term) ||
					bugReport.email?.includes(term) ||
					bugReport.reportPageUrl.includes(term) ||
					bugReport._id.includes(term),
			);
		};

		if (searchQuery || selectedProduct !== "All" || selectedCategory !== "All") {
			setFilteredBugReports(
				bugReportsList.filter(
					(bugReport) =>
						correctCategory(bugReport) &&
						correctProduct(bugReport) &&
						includesSearchQuery(bugReport),
				),
			);
		} else {
			setFilteredBugReports(bugReportsList);
		}
	}, [bugReportsList, searchQuery, selectedCategory, selectedProduct]);

	return (
		<Card>
			<CardHeader title="Bug Reports" />
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<FormControl sx={{ m: 1, minWidth: 120 }}>
							<InputLabel id="select-status">Status</InputLabel>
							<Select
								id="select-status"
								label="Status"
								value={selectedStatus}
								onChange={(newStatus) =>
									onStatusSelected(newStatus.target.value as AvailableBugStatus)
								}
							>
								{Object.values(BUG_REPORT_STATUS).map((value) => (
									<MenuItem key={value} value={value}>
										{value}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>
								Open: not ingested yet.
								<br />
								Closed: ingested and resolved.
								<br />
								Rejected: nothing to do/not a bug
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<FormControl fullWidth sx={{ m: 1 }}>
							<InputLabel id="select-product">Product</InputLabel>
							<Select
								id="select-product"
								label="Product"
								value={selectedProduct}
								onChange={(newProduct) =>
									setSelectedProduct(
										newProduct.target.value as AvailableBugProduct | "All",
									)
								}
							>
								{["All", ...Object.values(BUG_REPORT_PRODUCTS)].map((value) => (
									<MenuItem key={value} value={value}>
										{value}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<FormControl fullWidth sx={{ m: 1 }}>
							<InputLabel id="select-category">Category</InputLabel>
							<Select
								id="select-category"
								label="Category"
								value={selectedCategory}
								onChange={(newCategory) =>
									setSelectedCategory(
										newCategory.target.value as AvailableBugCategory | "All",
									)
								}
							>
								{["All", ...Object.values(BUG_REPORT_CATEGORIES)].map((value) => (
									<MenuItem key={value} value={value}>
										{value}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label="Search by api, cruncho_slug and api_category_id"
							onChange={(event) => {
								// keeping track of the current search query
								setSearchQuery(event.target.value);
							}}
							value={searchQuery}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								endAdornment: searchQuery && (
									<InputAdornment position="end">
										<IconButton onClick={() => setSearchQuery("")} size="large">
											<ClearIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
							sx={{ m: 1 }}
						/>
					</Grid>
				</Grid>
			</CardContent>

			<CardContent>
				<Typography variant="body1" color="textSecondary">
					Bug Reports
				</Typography>
				<List
					style={{ maxHeight: "600px", overflow: "auto", scrollbarGutter: "stable" }}
				>
					{filteredBugReports.sort(sortBugReports).map((bugReport) => (
						<ListItemButton
							key={bugReport._id}
							selected={!!selectedBugReport && selectedBugReport._id === bugReport._id}
							onClick={() => onBugReportSelected(bugReport)}
						>{`${bugReport.product} - ${bugReport.categories.join(
							" ",
						)}`}</ListItemButton>
					))}
				</List>
			</CardContent>
		</Card>
	);
}
