import {
	StringSelectWithDescriptionField,
	NumberWithDescriptionField,
} from "@cruncho/components";
import { SCORING_SCRIPTS } from "@cruncho/cruncho-shared-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

/**
 * Section of fields related to cruncho scores and hooked to formik
 */
export function CrunchoScoreSection() {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h6">Cruncho Score </Typography>
			</Grid>

			<Grid item xl={4} lg={6} xs={12}>
				<StringSelectWithDescriptionField
					name="Scoring script name"
					description="This is the name of the script the client will use to score the recommendations."
					databaseName="features.scoringScript"
					arrayOptions={[...SCORING_SCRIPTS]}
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<NumberWithDescriptionField
					name="Minimum number of photos"
					databaseName="features.thresholdMinPhotos"
					description="You will not find any recommendation on the guide with less photos than this value."
					minValue={0}
					step={1}
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<NumberWithDescriptionField
					name="Minimum number of reviews"
					databaseName="features.thresholdMinReviews"
					description="You will not find any recommendation on the guide with less reviews than this value."
					minValue={0}
					step={1}
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<NumberWithDescriptionField
					name="Minimum score"
					databaseName="features.thresholdMinScore"
					description="You will not find any recommendation on the guide with a lower score than this value, expressed as a number of stars, between 0 and 5."
					maxValue={5}
					minValue={0}
					step={0.5}
				/>
			</Grid>
		</Grid>
	);
}
