import { AutocompleteField, SelectField, TextField } from "@cruncho/components";
import {
	COUNTRY_CODES,
	Destination,
	destinationSchema,
} from "@cruncho/cruncho-shared-types";
import { defaultCity } from "@cruncho/utils/helpers";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import axios, { AxiosError } from "axios";
import { Form, Formik, prepareDataForValidation } from "formik";
import { cloneDeep } from "lodash";
import { useSnackbar } from "notistack";
import { api } from "services/api";
import { sanitize } from "utils";

import { toFormikValidationSchema } from "zod-formik-adapter";

interface CreateDestinationDialogProps {
	/**
	 * Triggered when clicking the cancel button
	 */
	onCancel: () => void;
	/**
	 * Triggered when clicking the create button
	 */
	onCreate: (destination: Destination) => void;
	/**
	 * If true, show this dialog
	 */
	show: boolean;
}

const initialValues = cloneDeep(defaultCity);
// removing unused empty fields
delete initialValues.features.eventManagerFeatures;

/**
 * A simple dialog to create a new Destination.
 */
export function CreateDestinationDialog({
	onCancel,
	onCreate,
	show,
}: CreateDestinationDialogProps) {
	const { enqueueSnackbar } = useSnackbar();

	return (
		<Dialog open={show} onClose={onCancel} fullWidth>
			<Formik
				validationSchema={toFormikValidationSchema(
					destinationSchema.omit({ _id: true }),
				)}
				initialValues={initialValues}
				onSubmit={async (values) => {
					const preparedData = prepareDataForValidation(values);

					try {
						const parsedDestination = destinationSchema
							.omit({ _id: true })
							.parse(preparedData);

						/**
						 * Creating the destination in db
						 */
						const createdDestination = await api.destination.create({
							...parsedDestination,
							_id: sanitize(parsedDestination.name),
						});

						onCreate(createdDestination);
					} catch (error) {
						console.error(error);
						if (
							axios.isAxiosError(error) &&
							(error as AxiosError<{ message: string }>).response?.data?.message
						) {
							enqueueSnackbar(
								(error as AxiosError<{ message: string }>).response?.data?.message,
								{
									variant: "error",
									persist: true,
								},
							);
						} else {
							enqueueSnackbar(JSON.stringify(error, null, 2), {
								variant: "error",
								persist: true,
							});
						}
					}
				}}
			>
				{({ errors }) => (
					<Form>
						<DialogTitle>Create a new destination</DialogTitle>

						<DialogContent>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField fullWidth name="name" label="Destination name" />
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										name="subdomainMatch"
										label="Destination subdomains"
									/>
								</Grid>
								<Grid item xs={12} sx={{ mb: 2 }}>
									<TextField fullWidth name="clientName" label="Client Name" />
								</Grid>
								<Grid item xs={12} md={6}>
									<AutocompleteField
										freeSolo
										multiple
										fullWidth
										options={[...COUNTRY_CODES]}
										name="features.availableCCs"
										textFieldProps={{
											label: "Available Country Codes",
											variant: "outlined",
										}}
										ChipProps={{
											size: "small",
										}}
									/>
								</Grid>

								<Grid item xs={12} md={6}>
									<SelectField
										fullWidth
										label="Destination Client type"
										variant="outlined"
										items={["DMO", "Media"].map((clientType) => ({
											value: clientType,
											label: clientType,
										}))}
										name="clientType"
									/>
								</Grid>

								<Grid item xs={12} md={6}>
									<SelectField
										fullWidth
										label="Country code"
										variant="outlined"
										items={[...COUNTRY_CODES].map((clientType) => ({
											value: clientType,
											label: clientType,
										}))}
										name="features.countryCode"
									/>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button onClick={onCancel} autoFocus aria-label="cancel">
								Cancel
							</Button>
							<Button
								type="submit"
								aria-label="create"
								onClick={() => {
									if (Object.keys(errors).length > 0) {
										console.error(errors);
									}
								}}
							>
								Create
							</Button>
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
}
