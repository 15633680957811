import { ChildCategory } from "@cruncho/cruncho-shared-types";
import { APIBase } from "../APIBase";

/**
 * A class handling the interractions with l2-related endpoints in the api
 */
export class APIL2 extends APIBase {
	async get(id: string) {
		const response = await this.api.get<ChildCategory>(`/categories/l2/${id}`);
		return response.data;
	}

	async getAll() {
		const response = await this.api.get<Array<ChildCategory>>("/categories/l2");
		return response.data;
	}

	async update(id: string, category: ChildCategory) {
		const response = await this.api.put<ChildCategory>(
			`/categories/l2/${id}`,
			category,
		);
		return response.data;
	}

	async create(category: ChildCategory) {
		const response = await this.api.post<ChildCategory>(
			"/categories/l2",
			category,
		);
		return response.data;
	}
}
