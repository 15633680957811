import { SoulcircusCategory } from "@cruncho/cruncho-shared-types";
import { APIBase } from "../APIBase";

/**
 * A class handling the interactions with formats endpoints in the api
 */
export class APISoulcircusCategory extends APIBase {
	async getAllByType(categoryType: string) {
		const response = await this.api.get<SoulcircusCategory[]>(
			"/soulcircus/category",
			{
				params: { categoryType },
			},
		);
		return response.data;
	}

	async create(category: SoulcircusCategory) {
		const response = await this.api.post<SoulcircusCategory>(
			"/soulcircus/category",
			category,
		);
		return response.data;
	}

	async update(slug: string, category: SoulcircusCategory) {
		const response = await this.api.put<SoulcircusCategory>(
			`/soulcircus/category/${slug}`,
			category,
		);
		return response.data;
	}
}
