import {
	StringSelectWithDescriptionField,
	StringWithDescriptionField,
} from "@cruncho/components";
import {
	COUNTRY_CODES,
	CURRENCY_CODES,
	TIME_ZONES,
} from "@cruncho/cruncho-shared-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

/**
 * Section of fields related to clients informatiosn and hooked to formik
 */
export function ClientInformationsSection() {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h6">Client informations </Typography>
			</Grid>

			<Grid item xl={4} lg={6} xs={12}>
				<StringSelectWithDescriptionField
					name="Country code"
					arrayOptions={[...COUNTRY_CODES]}
					databaseName="features.countryCode"
					description="This is the client's country code."
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<StringSelectWithDescriptionField
					name="Country currency"
					arrayOptions={[...CURRENCY_CODES]}
					databaseName="features.currencyCode"
					description="This is the currency of the client's country."
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<StringSelectWithDescriptionField
					name="Time Zone"
					arrayOptions={[...TIME_ZONES]}
					description="This is the timezone in the client's region."
					databaseName="features.timeZone"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<StringWithDescriptionField
					name="Client hostname"
					description="This is the hostname of the client website used when sharing recommendations"
					databaseName="features.clientHostname"
				/>
			</Grid>
		</Grid>
	);
}
