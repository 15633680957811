import { TextField } from "@cruncho/components";
import {
	ExperienceSection,
	experienceSectionSchema,
} from "@cruncho/cruncho-shared-types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import axios, { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { api } from "services/api";
import { toFormikValidationSchema } from "zod-formik-adapter";

interface ExperienceSectionItemProps {
	index: number;
	experienceSectionData: ExperienceSection[];
	practitionerId: string;
}

export function ExperienceSectionItem({
	index,
	experienceSectionData,
	practitionerId,
}: ExperienceSectionItemProps) {
	const { enqueueSnackbar } = useSnackbar();

	const initialValues = {
		description: experienceSectionData[index].description,
		endDate: experienceSectionData[index].endDate,
		startDate: experienceSectionData[index].startDate,
		title: experienceSectionData[index].title,
		city: experienceSectionData[index].city ?? "",
		country: experienceSectionData[index].country ?? "",
		currentlyInTheRole: experienceSectionData[index].currentlyInTheRole ?? false,
		place: experienceSectionData[index].place ?? "",
	};

	return (
		<ListItem>
			<Formik
				initialValues={initialValues}
				onSubmit={async (values, submitProps) => {
					try {
						experienceSectionData[index] = values;
						const toUpdateData = {
							experienceSection: experienceSectionData,
						};
						const res = await api.practitioner.updatePractitioner(
							practitionerId,
							toUpdateData,
						);

						if (res)
							enqueueSnackbar(`${res.firstname} updated!`, {
								variant: "success",
							});

						submitProps.resetForm({ values });
					} catch (error: any) {
						console.error(error);
						if (
							axios.isAxiosError(error) &&
							(error as AxiosError<{ message: string }>).response?.data?.message
						) {
							enqueueSnackbar(
								(error as AxiosError<{ message: string }>).response?.data?.message,
								{
									variant: "error",
									persist: true,
								},
							);
						} else {
							enqueueSnackbar(JSON.stringify(error, null, 2), {
								variant: "error",
								persist: true,
							});
						}
					}
				}}
				validationSchema={toFormikValidationSchema(experienceSectionSchema)}
			>
				{({ dirty, isSubmitting, isValid }) => (
					<Form>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<ListItemText
									primary={
										<Typography fontWeight="bold">{`Title of experience: ${experienceSectionData[index].title}`}</Typography>
									}
									secondary={`${new Date(
										experienceSectionData[index].startDate,
									).toUTCString()} - ${new Date(
										experienceSectionData[index].endDate,
									).toUTCString()}`}
									style={{
										fontStyle: "",
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									name="title"
									multiline
									label="Title"
									variant="standard"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									name="description"
									multiline
									label="Description of this experience"
									variant="standard"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									name="city"
									multiline
									label="City"
									variant="standard"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									name="country"
									multiline
									label="Country"
									variant="standard"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									name="place"
									multiline
									label="Place"
									variant="standard"
								/>
							</Grid>
							<Grid item xs={12}>
								<Button
									disabled={!dirty || isSubmitting || !isValid}
									type="submit"
									variant="contained"
								>
									Save this experience
								</Button>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</ListItem>
	);
}
