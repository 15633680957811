import { Format } from "@cruncho/cruncho-shared-types";

import EditIcon from "@mui/icons-material/Edit";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

type FormatListItemProps = {
	format?: Format;

	/**
	 * A callback triggered when clicking the edit button
	 */
	onEditSelected: (format?: Format) => void;
};

/**
 * A component to display one format in a list row
 */
export function FormatListItem({
	format,
	onEditSelected,
}: FormatListItemProps) {
	return (
		<ListItemButton>
			{format ? (
				<ListItemText
					primary={format.slug}
					secondary={`${format.isDefault ? "Default - " : ""}${format.slug}`}
				/>
			) : (
				<ListItemText primary="ALL" />
			)}

			{format?.hideOnEM && (
				<Chip label="Hidden on EM" variant="outlined" color="warning" />
			)}

			{format && (
				<IconButton
					title="Edit this format"
					aria-label="edit this format"
					edge="end"
					onClick={() => {
						onEditSelected(format);
					}}
					size="large"
				>
					<EditIcon />
				</IconButton>
			)}
		</ListItemButton>
	);
}
