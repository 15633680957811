import { NumberWithDescriptionField } from "@cruncho/components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export function EventDeletionSection() {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h6">Event deletion</Typography>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<NumberWithDescriptionField
					name="Numbers of months after endDate to delete events from DB"
					databaseName="features.eventManagerFeatures.deleteEventsNumberMonths"
					description="After the given number of months, events from this destination will be removed from the database"
					minValue={1}
					step={1}
				/>
			</Grid>
		</Grid>
	);
}
