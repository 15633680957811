import {
	CategoryMapping,
	categoryMappingSchema,
} from "@cruncho/cruncho-shared-types";
import Dialog from "@mui/material/Dialog";
import axios, { AxiosError } from "axios";
import { Formik, prepareDataForValidation } from "formik";
import { useSnackbar } from "notistack";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { api } from "../../services/api";
import { CreateOrEditCategoryMappingForm } from "./CreateOrEditCategoryMappingForm";

type EditCategoryMappingDialogProps = {
	/**
	 * Mapping to edit
	 */
	mapping: CategoryMapping;
	/**
	 * Function called on close (without saving document)
	 */
	onClose: () => void;
	/**
	 * Function called when clicking on "save"
	 */
	onSave: (_mapping: CategoryMapping) => void;
	/**
	 * Control display state of dialog
	 */
	open: boolean;
};

/**
 * A dialog to create or edit an existing category mapping.
 */
export function EditCategoryMappingDialog({
	onClose,
	onSave,
	mapping,
	open,
}: EditCategoryMappingDialogProps) {
	const { enqueueSnackbar } = useSnackbar();

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<Formik
				enableReinitialize
				validationSchema={toFormikValidationSchema(categoryMappingSchema)}
				initialValues={mapping}
				onSubmit={async (values) => {
					const preparedData = prepareDataForValidation(values);
					try {
						const parsedCategoryMapping = categoryMappingSchema.parse(preparedData);
						if (parsedCategoryMapping.destinationSlug === "All destinations") {
							delete parsedCategoryMapping.destinationSlug;
						}
						const updated = await api.category.mappings.update(parsedCategoryMapping);
						enqueueSnackbar(`${updated.apiCategoryId} updated!`, {
							variant: "success",
						});
						onSave(updated);
					} catch (error: any) {
						console.error(error);
						if (
							axios.isAxiosError(error) &&
							(error as AxiosError<{ message: string }>).response?.data?.message
						) {
							enqueueSnackbar(
								(error as AxiosError<{ message: string }>).response?.data?.message,
								{
									variant: "error",
									persist: true,
								},
							);
						} else {
							enqueueSnackbar(JSON.stringify(error, null, 2), {
								variant: "error",
								persist: true,
							});
						}
					}
				}}
			>
				{({ dirty, isSubmitting }) => (
					<CreateOrEditCategoryMappingForm
						dirty={dirty}
						isSubmitting={isSubmitting}
						mapping={mapping}
						onClose={onClose}
					/>
				)}
			</Formik>
		</Dialog>
	);
}
