import { SessionMe } from "@cruncho/cruncho-shared-types";
import { AxiosInstance } from "axios";
import { paths } from "routes";
import {
	environmentToWebroot,
	environmentToUrl,
	currentEnvironment,
} from "./api";
import { APIBase } from "./APIBase";

/**
 * A class handling the interactions with auth related endpoints
 */
export class APIAuth extends APIBase {
	private token: string | null;

	private interceptor: number | null;

	constructor(instance: AxiosInstance) {
		super(instance);

		this.token = localStorage.getItem("token");
		this.interceptor = null;
		if (this.token) {
			this.setInterceptor();
		}
	}

	private setInterceptor() {
		this.interceptor = this.api.interceptors.request.use((config) => {
			config.headers.Authorization = `Bearer ${this.token}`;

			return config;
		});
	}

	public async isConnected(): Promise<boolean> {
		try {
			const session = await this.api.get<SessionMe>("/session/me");
			return !!session.data.adminGroup && session.data.adminGroup.superAdmin;
		} catch (error) {
			this.logout();
			throw error;
		}
	}

	public async connect() {
		if (!this.token) {
			this.token = (await this.api.get("/session/new/admin")).data.token;
			localStorage.setItem("token", this.token ?? "");
		}
		if (!this.interceptor) {
			this.setInterceptor();
		}
		const redirect_uri = environmentToWebroot() + paths.login;
		window.location.assign(
			`${environmentToUrl[currentEnvironment]}/login/google?redirect_uri=${redirect_uri}&token=${this.token}`,
		);
	}

	public setToken(token: string) {
		this.token = token;
		localStorage.setItem("token", token);
	}

	public logout() {
		this.token = null;
		localStorage.removeItem("token");
		if (this.interceptor) {
			this.api.interceptors.request.eject(this.interceptor);
			this.interceptor = null;
		}
	}
}
