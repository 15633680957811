import LogoutIcon from "@mui/icons-material/Logout";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link as Routerlink, useNavigate } from "react-router-dom";
import { paths } from "./routes";
import { api, currentEnvironment } from "./services/api";

/**
 * The main header nav bar
 */
function MainNav() {
	const navigate = useNavigate();

	const logout = () => {
		api.auth.logout();
		navigate(paths.login);
	};

	return (
		<div>
			<AppBar position="fixed">
				<Toolbar>
					<Grid container alignItems="center">
						<Grid item margin="10px">
							<Typography
								component={Routerlink}
								to={paths.root}
								variant="h6"
								style={{ color: "white" }}
							>
								Admin
							</Typography>
						</Grid>

						<Grid item margin="10px">
							<Typography
								component={Routerlink}
								to={paths.destination}
								variant="h6"
								style={{ color: "white" }}
							>
								Destinations
							</Typography>
						</Grid>

						<Grid item margin="10px">
							<Typography
								component={Routerlink}
								to={paths.category}
								variant="h6"
								style={{ color: "white" }}
							>
								Categories
							</Typography>
						</Grid>

						<Grid item margin="10px">
							<Typography
								component={Routerlink}
								to={paths.categoryMapping}
								variant="h6"
								style={{ color: "white" }}
							>
								Mappings
							</Typography>
						</Grid>

						<Grid item margin="10px">
							<Typography
								component={Routerlink}
								to={paths.format}
								variant="h6"
								style={{ color: "white" }}
							>
								Formats
							</Typography>
						</Grid>

						<Grid item margin="10px">
							<Typography
								component={Routerlink}
								to={paths.iframeGenerator}
								variant="h6"
								style={{ color: "white" }}
							>
								Iframe generator
							</Typography>
						</Grid>

						<Grid item margin="10px">
							<Typography
								component={Routerlink}
								to={paths.widgetGenerator}
								variant="h6"
								style={{ color: "white" }}
							>
								Widget generator
							</Typography>
						</Grid>

						<Grid item margin="10px">
							<Typography
								component={Routerlink}
								to={paths.soulcircus}
								variant="h6"
								style={{ color: "white" }}
							>
								Soulcircus
							</Typography>
						</Grid>

						<Grid item margin="10px">
							<Typography
								component={Routerlink}
								to={paths.bugReports}
								variant="h6"
								style={{ color: "white" }}
							>
								Bug reports
							</Typography>
						</Grid>

						<Grid item margin="10px" style={{ flexGrow: 1 }}>
							<Typography
								component={Routerlink}
								to={paths.product}
								variant="h6"
								style={{ color: "white" }}
							>
								Products
							</Typography>
						</Grid>

						<Grid item>
							<Typography variant="h6">Environment: {currentEnvironment}</Typography>
						</Grid>

						<Grid item>
							<IconButton color="inherit" aria-labelledby="logout" onClick={logout}>
								<LogoutIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>

			<Toolbar />
		</div>
	);
}

export default MainNav;
