import { SoulcircusProduct } from "@cruncho/cruncho-shared-types";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useEffect, useState } from "react";

import { ProductListItem } from "./ProductListItem";

type ProductListProps = {
	/**
	 * The list of products to display
	 */
	products: SoulcircusProduct[];
	/**
	 * Triggered when clicking on the button to delete a category
	 */
	onProductDeleteSelected: (product?: SoulcircusProduct) => Promise<void>;
	/**
	 * Triggered when clicking on the button to edit a product
	 */
	onProductEditSelected: (product?: SoulcircusProduct) => void;
};

/**
 * A component displaying a list of products that can be filtered by name
 *
 */
export function ProductList({
	products,
	onProductDeleteSelected,
	onProductEditSelected,
}: ProductListProps) {
	/**
	 * A string used to filter the displayed products
	 */
	const [searchQuery, setSearchQuery] = useState<string>("");

	/**
	 * The subset of products kept while filtering
	 */
	const [filteredProducts, setFilteredProducts] = useState<SoulcircusProduct[]>(
		[],
	);

	/**
	 *  When the parent changes the list of products, sort them alphabetically and apply search quey
	 * Will search by slug and name
	 */
	useEffect(() => {
		if (searchQuery) {
			setFilteredProducts(
				products.filter((product) => product.name.includes(searchQuery)),
			);
		} else {
			setFilteredProducts(products);
		}
	}, [products, searchQuery]);

	return (
		<Card>
			<CardHeader
				title="Products"
				action={<Typography>({filteredProducts.length})</Typography>}
			/>
			<CardContent>
				{products.length > 0 && (
					<TextField
						fullWidth
						label="Search"
						onChange={(event) => {
							// keeping track of the current search query
							setSearchQuery(event.target.value);
						}}
						value={searchQuery}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
							endAdornment: searchQuery && (
								<InputAdornment position="end">
									<IconButton
										onClick={() => setSearchQuery("")}
										size="large"
										aria-label="clear"
									>
										<ClearIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				)}

				{filteredProducts.length > 0 ? (
					<List>
						{filteredProducts.map((product) => (
							<ProductListItem
								key={product.name}
								product={product}
								onDeleteSelected={(_product) => onProductDeleteSelected(_product)}
								onEditSelected={(_product) => onProductEditSelected(_product)}
							/>
						))}
					</List>
				) : (
					<Box margin={2}>
						<Typography variant="body2" color="textSecondary">
							No products available
						</Typography>
					</Box>
				)}
			</CardContent>
		</Card>
	);
}
