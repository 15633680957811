import { SelectField, TextField } from "@cruncho/components";
import { SoulcircusCategory } from "@cruncho/cruncho-shared-types";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import axios, { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { api } from "../../services/api";

type EditSoulcircusCategoryDialogProps = {
	category: SoulcircusCategory;
	onClose: () => void;
	onSave: (category: string) => void;
	open: boolean;
};

/**
 * A dialog to edit an existing format
 */
export function EditSoulcircusCategoryDialog({
	category,
	onClose,
	onSave,
	open,
}: EditSoulcircusCategoryDialogProps) {
	const { enqueueSnackbar } = useSnackbar();

	const initialValues = category;

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={async (values) => {
					try {
						try {
							// @ts-ignore Typescript is not able to follow that the parsedFormat is the same as from the input
							const updatedCategory = await api.soulcircusCategory.update(
								category.slug,
								values,
							);

							enqueueSnackbar(`${updatedCategory.slug} created!`, {
								variant: "success",
							});

							// @ts-ignore
							onSave(updatedCategory);
						} catch (error: any) {
							console.error(error);
							if (axios.isAxiosError(error) && error.response?.status === 400) {
								enqueueSnackbar("A format with the same slug/name already exists", {
									variant: "warning",
									persist: true,
								});
							} else if (
								axios.isAxiosError(error) &&
								(error as AxiosError<{ message: string }>).response?.data?.message
							) {
								enqueueSnackbar(
									(error as AxiosError<{ message: string }>).response?.data?.message,
									{
										variant: "error",
										persist: true,
									},
								);
							} else {
								enqueueSnackbar(JSON.stringify(error, null, 2), {
									variant: "error",
									persist: true,
								});
							}
						}
					} catch (error) {
						console.error(error);
						enqueueSnackbar(JSON.stringify(error, null, 2), {
							variant: "error",
							persist: true,
						});
					}
				}}
			>
				{({ dirty, isSubmitting }) => (
					<Form>
						<DialogTitle>Edit Category : {category.slug}</DialogTitle>

						<DialogContent>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography gutterBottom variant="h6">
										General information
									</Typography>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<TextField fullWidth name="slug" label="Slug" variant="standard" />
										</Grid>
										<Grid item xs={6}>
											<SelectField
												size="small"
												fullWidth
												label="Type of category"
												name="type"
												items={
													["listing", "issues", "profession"].map((option) => ({
														value: option,
														label: option,
													})) ?? []
												}
												variant="outlined"
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</DialogContent>

						<DialogActions>
							<Button onClick={onClose} color="primary">
								Cancel
							</Button>
							{dirty && (
								<Button
									type="submit"
									color="secondary"
									variant="outlined"
									disabled={isSubmitting}
								>
									Save
								</Button>
							)}
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
}
