import {
	BooleanWithDescriptionField,
	L1DependantBooleanWithDescriptionField,
	L1DependantNumberWithDescriptionField,
	StringArrayWithDescriptionField,
} from "@cruncho/components";
import { Destination } from "@cruncho/cruncho-shared-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { api } from "services/api";

/**
 * Section of fields related to Recommendations and hooked to formik
 */
export function RecommendationsSection() {
	const [destinations, setDestinations] = useState<string[]>([]);
	useEffect(() => {
		api.destination.getAll().then((newDestinations) => {
			setDestinations(
				newDestinations.map((destination: Destination) => destination._id),
			);
		});
	}, []);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h6">Recommendations</Typography>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Covid Safe ribbon text"
					description="This is the text displayed on the Covid Safe ribbons."
					databaseName="features.cardRibbonText"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Hide HotelsCombined links"
					description="When activated, the HotelsCombined book buttons and prices are hidden."
					databaseName="features.hideHotelsCombinedPricesAndLinks"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Hide the rating bar"
					description="When activated, the rating bar shown in the recommendation list page is hidden."
					databaseName="features.hideRatingBar"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Hide the rating on details"
					description="When activated, the rating bar shown in the recommendation details page and the rating breakdown are hidden."
					databaseName="features.hideRatingDetails"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Hide text on preview"
					description="When activated, the review or description usually appearing on the preview of a recommendation is hidden."
					databaseName="features.hideRecoDescription"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Prioritize descriptions"
					description="When activated, when there is one, the description of a recommendation replaces the review on the preview."
					databaseName="features.prioDescriptionOnCard"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<L1DependantBooleanWithDescriptionField
					name="Show prices"
					description="When activated, the available starting price for a recommendation is shown."
					databaseName="features.showPrices"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<L1DependantBooleanWithDescriptionField
					name="Book buttons"
					description="When activated the book buttons are shown on the guide. This value can be adapted for each section separately."
					databaseName="features.showBookButton"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<L1DependantNumberWithDescriptionField
					name="Reviews maximum age"
					description="Reviews written longer ago than this value are hidden. This value must be shown in days and can be adapted for each section separately."
					databaseName="features.reviewsMaxAge"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<StringArrayWithDescriptionField
					name="Sync from destinations"
					description="Adds to the content of the destination the recommendations imported from these destinations, except event manager events to keep the normal behaviour of post options."
					databaseName="features.syncFromDestinations"
					arrayOptions={destinations}
				/>
			</Grid>
			<Grid item container spacing={2} xl={4} lg={6} xs={12} className="h-fit">
				<Grid item xs={12}>
					<BooleanWithDescriptionField
						name="Exclude recos outside of areas"
						description="When activated, recos that are not inside a sub-area will never be displayed."
						databaseName="features.excludeRecosOutsideAreas"
					/>
				</Grid>
				<Grid item xs={12}>
					<BooleanWithDescriptionField
						name="Show venue name"
						description="When activated, the venue name of an event appears on the preview."
						databaseName="features.showVenueName"
					/>
				</Grid>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<L1DependantBooleanWithDescriptionField
					name="Hide non bookable recos"
					description="When activated, the recos that are not bookable are hidde, and the 'Only bookable' button is hidden. This value can be adapted for each section separately."
					databaseName="features.hideNonBookableContent"
				/>
			</Grid>
		</Grid>
	);
}
