import { SoulcircusCategory } from "@cruncho/cruncho-shared-types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { api } from "services/api";
import { CreateSoulcircusCategoryDialog } from "./CreateSoulcircusCategoryDialog";
import { DeleteSoulcircusCategoryDialog } from "./DeleteSoulcircusCategoryDialog";
import { EditSoulcircusCategoryDialog } from "./EditSoulcircusCategoryDialog";
import { PractitionersList } from "./PractitionersList";
import { SoulcircusCategoryList } from "./SoulcircusCategoryList";

export function SoulcircusView() {
	// Handling of creation/editing modals :
	const [openModal, setOpenModal] = useState<boolean>(false);

	// Handling of deletion modal :
	const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

	/**
	 * An object containing information about the current type selected category
	 */
	const [selectedType, setSelectedType] = useState<string>();

	/**
	 * An object containing information about the current selected category, if it is undefined, it means we're creating a new category, and not editing
	 */
	const [editingCategory, setEditingCategory] = useState<SoulcircusCategory>();

	/**
	 * Triggered when a category is selected for edition
	 */
	const handleEditCategory = (category?: SoulcircusCategory) => {
		setEditingCategory(category);
		setOpenModal(true);
	};

	/**
	 * Triggered when a category is selected for deletion
	 */
	const handleDeleteCategory = (
		category?: SoulcircusCategory,
		type?: string,
	) => {
		setSelectedType(type);
		setEditingCategory(category);
		setOpenDeleteModal(true);
	};

	/**
	 * Triggered when creating a format
	 */
	const handleAddCategory = () => {
		setEditingCategory(undefined);
		setOpenModal(true);
	};

	// Fetch function used to fetch different type of categories :
	const fetchCategoriesByType = async (
		type: string,
	): Promise<SoulcircusCategory[]> => {
		const fetchedData = await api.soulcircusCategory.getAllByType(type);

		return fetchedData;
	};

	// Listing category : Determining in which carousels the practitioners are
	const [listingCategories, setListingCategories] = useState<
		SoulcircusCategory[]
	>([]);
	useEffect(() => {
		fetchCategoriesByType("listing").then((value) => setListingCategories(value));
	}, [openModal, openDeleteModal]);

	// Issues category : Categories shown on the mini practitioner card :
	const [issuesCategories, setIssuesCategories] = useState<SoulcircusCategory[]>(
		[],
	);
	useEffect(() => {
		fetchCategoriesByType("issues").then((value) => setIssuesCategories(value));
	}, [openModal, openDeleteModal]);

	// Profession category : Categories shown in the IntroSection of the practitioner's mini website
	const [professionCategories, setProfessionCategories] = useState<
		SoulcircusCategory[]
	>([]);
	useEffect(() => {
		fetchCategoriesByType("profession").then((value) =>
			setProfessionCategories(value),
		);
	}, [openModal, openDeleteModal]);

	return (
		<div className="p-4">
			<Button
				variant="contained"
				color="primary"
				onClick={() => {
					handleAddCategory();
				}}
				className="mb-4"
			>
				Create new Category
			</Button>
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<SoulcircusCategoryList
						categories={listingCategories}
						name="listing"
						onCategoryEditSelected={handleEditCategory}
						onCategoryDeleteSelected={handleDeleteCategory}
					/>
				</Grid>
				<Grid item xs={4}>
					<SoulcircusCategoryList
						categories={issuesCategories}
						name="issues"
						onCategoryEditSelected={handleEditCategory}
						onCategoryDeleteSelected={handleDeleteCategory}
					/>
				</Grid>
				<Grid item xs={4}>
					<SoulcircusCategoryList
						categories={professionCategories}
						name="profession"
						onCategoryEditSelected={handleEditCategory}
						onCategoryDeleteSelected={handleDeleteCategory}
					/>
				</Grid>
				<Grid item xs={12}>
					<PractitionersList />
				</Grid>
			</Grid>
			{/* if the current category is undefined, it means we are in creation mode else, it's edit mode */}
			{editingCategory ? (
				<>
					<EditSoulcircusCategoryDialog
						open={openModal}
						category={editingCategory}
						onClose={() => setOpenModal(false)}
						onSave={async () => {
							setOpenModal(false);
							setEditingCategory(undefined);
						}}
					/>
					<DeleteSoulcircusCategoryDialog
						open={openDeleteModal}
						category={editingCategory}
						onClose={() => setOpenDeleteModal(false)}
						onDelete={() => {
							setSelectedType(undefined);
							setOpenDeleteModal(false);
							setEditingCategory(undefined);
						}}
						selectedType={selectedType ?? ""}
					/>
				</>
			) : (
				<CreateSoulcircusCategoryDialog
					open={openModal}
					onClose={() => setOpenModal(false)}
					onSave={async () => {
						setOpenModal(false);
						setEditingCategory(undefined);
					}}
				/>
			)}
		</div>
	);
}
