import {
	NumberWithDescriptionField,
	StringSelectWithDescriptionField,
	StringWithDescriptionField,
} from "@cruncho/components";
import { COUNTRY_CODES_ISO } from "@cruncho/cruncho-shared-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

/**
 * Section of fields related to default location and hooked to formik
 */
export function DefaultLocationSection() {
	return (
		<div>
			<Typography variant="h6">Default Location</Typography>
			<Grid container spacing={2}>
				<Grid item xl={4} lg={6} xs={12}>
					<NumberWithDescriptionField
						name="Default Location Coordinates Lat"
						description="TODO"
						databaseName="features.eventManagerFeatures.defaultLocation.coordinates.lat"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<NumberWithDescriptionField
						name="Default Location Coordinates Lon"
						description="TODO"
						databaseName="features.eventManagerFeatures.defaultLocation.coordinates.lng"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringWithDescriptionField
						name="Default Location Address"
						description="TODO"
						databaseName="features.eventManagerFeatures.defaultLocation.address"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringWithDescriptionField
						name="Default Location Venue"
						description="TODO"
						databaseName="features.eventManagerFeatures.defaultLocation.venue"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringWithDescriptionField
						name="Default Location City"
						description="TODO"
						databaseName="features.eventManagerFeatures.defaultLocation.city"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringSelectWithDescriptionField
						name="Default Location Country"
						arrayOptions={[...COUNTRY_CODES_ISO]}
						description="TODO"
						databaseName="features.eventManagerFeatures.defaultLocation.country"
					/>
				</Grid>
			</Grid>
		</div>
	);
}
