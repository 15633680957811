import { SwitchField, TextField } from "@cruncho/components";
import { Format, formatSchema } from "@cruncho/cruncho-shared-types";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import axios, { AxiosError } from "axios";
import { Form, Formik, prepareDataForValidation } from "formik";
import { useSnackbar } from "notistack";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { api } from "../../services/api";

type EditFormatDialogProps = {
	format: Format;
	onClose: () => void;
	onSave: (format: Format) => void;
	open: boolean;
};

/**
 * A dialog to edit an existing format
 */
export function EditFormatDialog({
	format,
	onClose,
	onSave,
	open,
}: EditFormatDialogProps) {
	const { enqueueSnackbar } = useSnackbar();

	const schema = formatSchema;

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<Formik
				enableReinitialize
				validationSchema={toFormikValidationSchema(schema)}
				initialValues={format}
				onSubmit={async (values) => {
					const preparedData = prepareDataForValidation(values);
					try {
						const parsedFormat = schema.parse(preparedData);

						try {
							const updatedFormat = await api.format.update(format.slug, parsedFormat);

							enqueueSnackbar(`${updatedFormat.slug} created!`, {
								variant: "success",
							});

							onSave(updatedFormat);
						} catch (error: any) {
							console.error(error);
							if (axios.isAxiosError(error) && error.response?.status === 400) {
								enqueueSnackbar("A format with the same slug/name already exists", {
									variant: "warning",
									persist: true,
								});
							} else if (
								axios.isAxiosError(error) &&
								(error as AxiosError<{ message: string }>).response?.data?.message
							) {
								enqueueSnackbar(
									(error as AxiosError<{ message: string }>).response?.data?.message,
									{
										variant: "error",
										persist: true,
									},
								);
							} else {
								enqueueSnackbar(JSON.stringify(error, null, 2), {
									variant: "error",
									persist: true,
								});
							}
						}
					} catch (error) {
						console.error(error);
						enqueueSnackbar(JSON.stringify(error, null, 2), {
							variant: "error",
							persist: true,
						});
					}
				}}
			>
				{({ dirty, isSubmitting }) => (
					<Form>
						<DialogTitle>Edit Format : {format.slug}</DialogTitle>

						<DialogContent>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography gutterBottom variant="h6">
										General information
									</Typography>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<TextField
												fullWidth
												name="displayName"
												label="Name"
												variant="standard"
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField fullWidth name="slug" label="Slug" variant="standard" />
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12}>
									<Typography gutterBottom variant="h6">
										Customization
									</Typography>
									<SwitchField
										label="Is a Default category?"
										name="isDefault"
										color="secondary"
									/>
									<SwitchField
										label="Hide on Event Manager"
										name="hideOnEM"
										color="secondary"
									/>
								</Grid>
							</Grid>
						</DialogContent>

						<DialogActions>
							<Button onClick={onClose} color="primary">
								Cancel
							</Button>
							{dirty && (
								<Button
									type="submit"
									color="secondary"
									variant="outlined"
									disabled={isSubmitting}
								>
									Save
								</Button>
							)}
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
}
