import {
	BooleanWithDescriptionField,
	StringArrayWithDescriptionField,
	StringSelectWithDescriptionField,
	StringWithDescriptionField,
	SwitchInput,
} from "@cruncho/components";
import { REACT_PHONE_INPUT_LANGUAGE_CODES } from "@cruncho/cruncho-shared-types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useSchemaConfig } from "../FormikDestinationWrapper";

/**
 * An accordion allowing to edit phone format related info.
 * Hooked to formik to allow toggling this option
 */
export function PhoneFormatAccordion() {
	const { enablePhoneFormat, setEnablePhoneFormat } = useSchemaConfig();

	return (
		<Accordion expanded={enablePhoneFormat} sx={{ width: "100%" }}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="h6">Phone Format: </Typography>
				<SwitchInput
					label="Enable"
					value={enablePhoneFormat}
					onChange={(event: any) => setEnablePhoneFormat(event.target.checked)}
				/>
			</AccordionSummary>

			<AccordionDetails>
				<Grid container spacing={2}>
					<Grid item xl={4} lg={6} xs={12}>
						<StringSelectWithDescriptionField
							name="Phone Format Country"
							arrayOptions={[...REACT_PHONE_INPUT_LANGUAGE_CODES]}
							description="The country of the phone format"
							databaseName="features.eventManagerFeatures.phoneFormat.country"
						/>
					</Grid>
					<Grid item xl={4} lg={6} xs={12}>
						<StringArrayWithDescriptionField
							name="Phone Format Only Countries"
							arrayOptions={[...REACT_PHONE_INPUT_LANGUAGE_CODES]}
							description=""
							databaseName="features.eventManagerFeatures.phoneFormat.onlyCountries"
						/>
					</Grid>
					<Grid item xl={4} lg={6} xs={12}>
						<BooleanWithDescriptionField
							name="Phone Format Dial Code"
							description=""
							databaseName="features.eventManagerFeatures.phoneFormat.dialCode"
						/>
					</Grid>
					<Grid item xl={4} lg={6} xs={12}>
						<BooleanWithDescriptionField
							name="Phone Format Disable Country Code"
							description=""
							databaseName="features.eventManagerFeatures.phoneFormat.disableCountryCode"
						/>
					</Grid>
					<Grid item xl={4} lg={6} xs={12}>
						<BooleanWithDescriptionField
							name="Phone Format Disable Dropdown"
							description=""
							databaseName="features.eventManagerFeatures.phoneFormat.disableDropdown"
						/>
					</Grid>
					<Grid item xl={4} lg={6} xs={12}>
						<StringWithDescriptionField
							name="Phone Format Disable Placeholder"
							description=""
							databaseName="features.eventManagerFeatures.phoneFormat.placeholder"
						/>
					</Grid>
					<Grid item xl={4} lg={6} xs={12}>
						<BooleanWithDescriptionField
							name="Phone Format Enable Territories"
							description=""
							databaseName="features.eventManagerFeatures.phoneFormat.enableTerritories"
						/>
					</Grid>
					{/* It's an object and not just a string: https://www.npmjs.com/package/react-phone-input-2#custom-masks  */}
					{/* <Grid item xl={4} lg={6} xs={12}>
						<StringWithDescriptionField
							name="Phone Format Masks"
							description="The mask used for the phone number format ( like .. ... ...)"
							databaseName="features.eventManagerFeatures.phoneFormat.masks"
						/>
					</Grid> */}
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
}
