import {
	BooleanWithDescriptionField,
	StringArrayWithDescriptionField,
	TextField,
} from "@cruncho/components";
import {
	L1,
	LandingPageCategoryConfiguration,
} from "@cruncho/cruncho-shared-types";
import DeleteIcon from "@mui/icons-material/Delete";
import ChevronUpIcon from "@mui/icons-material/ExpandLess";
import ChevronDownIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FieldArray, useField } from "formik";
import { useEffect, useState, useRef } from "react";
import { api } from "services/api";

const defaultOption: LandingPageCategoryConfiguration = {
	carouselName: "",
	l2: [],
	l3: [],
};

interface OptionItemProps {
	name: string;
	l1: string;
	moveUpEnabled: boolean;
	moveUp: () => void;
	moveDownEnabled: boolean;
	moveDown: () => void;
	remove: () => void;
}
function OptionItem({
	name,
	l1,
	moveUpEnabled,
	moveUp,
	moveDownEnabled,
	moveDown,
	remove,
}: OptionItemProps) {
	const [availableL2, setAvailableL2] = useState<string[]>();
	const [availableL3, setAvailableL3] = useState<string[]>();

	useEffect(() => {
		async function getAllLs() {
			const allL2 = (await api.category.l2.getAll())
				.filter((l2) => l2.parentSlug === l1)
				.map((l2) => l2.slug);
			setAvailableL2(allL2.sort());

			const allL3 = (await api.category.l3.getAll())
				.filter((l3) => allL2?.includes(l3.parentSlug))
				.map((l3) => l3.slug);
			setAvailableL3(allL3.sort());
		}

		getAllLs();
	}, []);

	return (
		<Grid container spacing={1} alignItems="center">
			<Grid item xs={3}>
				<TextField
					size="small"
					fullWidth
					variant="outlined"
					name={`${name}.carouselName`}
					label="Carousel Name"
				/>
			</Grid>
			<Grid item xs={3}>
				<StringArrayWithDescriptionField
					arrayOptions={availableL2}
					name="Order of categories - L2"
					description="Defines the order in which L2 categories appear on the guide."
					databaseName={`${name}.l2`}
				/>
			</Grid>
			<Grid item xs={3}>
				<StringArrayWithDescriptionField
					arrayOptions={availableL3}
					name="Order of categories - L3"
					description="Defines the order in which L3 categories appear on the guide."
					databaseName={`${name}.l3`}
				/>
			</Grid>
			<Grid item xs={3}>
				<BooleanWithDescriptionField
					name="Hide in filters"
					description="Hide this categories in filters"
					databaseName={`${name}.hideInFilters`}
				/>
			</Grid>
			<Grid item xs={3}>
				<IconButton />
				<Button variant="outlined" disabled={!moveUpEnabled} onClick={moveUp}>
					<ChevronUpIcon />
				</Button>
				<Button variant="outlined" disabled={!moveDownEnabled} onClick={moveDown}>
					<ChevronDownIcon />
				</Button>
				<Button variant="outlined" color="error" onClick={remove}>
					<DeleteIcon />
				</Button>
			</Grid>
		</Grid>
	);
}

interface LandingPageL1CardProps {
	l1: L1 | "topPicks";
	name: string;
}
export function LandingPageL1Card({ l1, name }: LandingPageL1CardProps) {
	const options =
		useField<Array<LandingPageCategoryConfiguration & { key: number }>>(
			`features.landingPage['${l1}']`,
		)[0].value ?? [];

	const keyCounter = useRef(0);

	function getNextKey() {
		keyCounter.current += 1;
		return keyCounter.current;
	}

	return (
		<Card>
			<CardHeader
				subheader={
					<Typography variant="h6" gutterBottom>
						{name}
					</Typography>
				}
			/>
			<CardContent>
				<FieldArray
					name={`features.landingPage['${l1}']`}
					render={(arrayHelpers) => (
						<Grid container rowSpacing={2} justifyContent="center">
							{options.map((postOption, index) => (
								<Grid item xs={12} key={postOption.key}>
									<OptionItem
										name={`features.landingPage[${l1}][${index}]`}
										l1={l1}
										moveUpEnabled={index > 0}
										moveUp={() => arrayHelpers.swap(index, index - 1)}
										moveDownEnabled={index < options.length - 1}
										moveDown={() => arrayHelpers.swap(index, index + 1)}
										remove={() => arrayHelpers.remove(index)}
									/>
								</Grid>
							))}

							<Grid item>
								<Button
									variant="outlined"
									type="submit"
									onClick={() => {
										arrayHelpers.push({ key: getNextKey(), ...defaultOption });
									}}
								>
									Add new landing page category configuration
								</Button>
							</Grid>
						</Grid>
					)}
				/>
			</CardContent>
		</Card>
	);
}

export function LandingPageSection() {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h6">Landing page</Typography>
				<Typography variant="subtitle1">
					Add fixed carousels for each L1 category. If you do not set a name, the
					first L2 will serve as the carousel name (Locize key). If there are no L2,
					it will be the first L3.
				</Typography>
			</Grid>

			<Grid item xs={12}>
				<Stack spacing={1}>
					<LandingPageL1Card l1="topPicks" name="Top Picks" />
					<LandingPageL1Card l1="visit" name="See & Do" />
					<LandingPageL1Card l1="restaurants" name="Eat & Drink" />
					<LandingPageL1Card l1="hotels" name="Hotels" />
					<LandingPageL1Card l1="events" name="Events" />
				</Stack>
			</Grid>
		</Grid>
	);
}
