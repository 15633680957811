import {
	StringArrayWithDescriptionField,
	StringSelectWithDescriptionField,
	StringWithDescriptionField,
} from "@cruncho/components";
import { APP_LANGUAGE_CODES } from "@cruncho/cruncho-shared-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

/**
 * Section of fields related to contact information and hooked to formik
 */
export function ContactInformationSection() {
	return (
		<div>
			<Typography variant="h6">Contact Information</Typography>
			<Grid container spacing={2}>
				<Grid item xl={4} lg={6} xs={12}>
					<StringArrayWithDescriptionField
						name="BCC (Blindly Copy)"
						freeSolo={true}
						description="A list of emails used in BCC mode. Write them one by one and press enter after each email. Those people will receive a mail when an event is approved in this destination."
						databaseName="features.eventManagerFeatures.contactInformation.bcc"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringArrayWithDescriptionField
						name="CC (Copy)"
						freeSolo={true}
						description="A list of emails used in CC mode. Write them one by one and press enter after each email. Those people will receive a mail when an event is approved in this destination."
						databaseName="features.eventManagerFeatures.contactInformation.cc"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringArrayWithDescriptionField
						name="Created Email Alert"
						freeSolo={true}
						description="A list of emails that should be alerted in case event creation. Write them one by one and press enter after each email."
						databaseName="features.eventManagerFeatures.contactInformation.createdEmailAlert"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringSelectWithDescriptionField
						name="Language"
						arrayOptions={[...APP_LANGUAGE_CODES]}
						description="The language used for contacts"
						databaseName="features.eventManagerFeatures.contactInformation.language"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringWithDescriptionField
						name="Mail"
						description="The email for contact information"
						databaseName="features.eventManagerFeatures.contactInformation.mail"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringWithDescriptionField
						name="Name"
						description="The contact's name"
						databaseName="features.eventManagerFeatures.contactInformation.name"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringWithDescriptionField
						name="Phone"
						description="The phone number of the contact"
						databaseName="features.eventManagerFeatures.contactInformation.phone"
					/>
				</Grid>
				<Grid item xl={4} lg={6} xs={12}>
					<StringWithDescriptionField
						name="Phone Formatted"
						description="The phone number of the contact in international formalt"
						databaseName="features.eventManagerFeatures.contactInformation.phoneFormatted"
					/>
				</Grid>
			</Grid>
		</div>
	);
}
