import {
	CategoryMapping,
	CategoryMappingCreateInput,
	PartialBy,
} from "@cruncho/cruncho-shared-types";
import { APIBase } from "../APIBase";

/**
 * A class handling the interactions with category mapping related endpoints in the api
 */
export class APIMappings extends APIBase {
	/**
	 * Retrieve a category mapping by its Mongo ID
	 *
	 * @param id Mongo ID as string
	 * @returns Category Mapping associated with the id
	 */
	async get(id: string) {
		const response = await this.api.get<CategoryMapping>(
			`/categories/mappings/${id}`,
		);
		return response.data;
	}

	/**
	 * Delete a category mapping by its Mongo ID
	 *
	 * @param id Mongo ID as string
	 * @returns Deleted Category Mapping associated with the id
	 */
	async delete(id: string) {
		const response = await this.api.delete<CategoryMapping>(
			`/categories/mappings/${id}`,
		);
		return response.data;
	}

	/**
	 * Retrieve all category mappings for all the external APIs
	 *
	 * @returns List of all mappings
	 */
	async getAll() {
		const response = await this.api.get<Array<CategoryMapping>>(
			"/categories/mappings",
		);
		return response.data;
	}

	/**
	 * Update a mapping based on its ID
	 *
	 * @param mapping Category Mapping to update
	 * @returns The new category mapping object
	 */
	async update(mapping: CategoryMapping) {
		const mappingToSend: PartialBy<CategoryMapping, "_id"> = { ...mapping };
		delete mappingToSend._id;

		const response = await this.api.put<CategoryMapping>(
			`/categories/mappings/${mapping._id}`,
			mappingToSend,
		);
		return response.data;
	}

	/**
	 * Create a new category mapping based on the triplet `{ api, cruncho_slug, api_category_id }`.
	 * If no cruncho_slug is provided, the API falls back to "to-edit".
	 * Creation will fail if the triplet is already in the database.
	 *
	 * @param mapping Category Mapping (without Mongo ID)
	 * @returns The new mapping object (with its Mongo ID)
	 */
	async create(mapping: PartialBy<CategoryMappingCreateInput, "crunchoSlug">) {
		const response = await this.api.post<CategoryMapping>(
			"/categories/mappings/",
			mapping,
		);
		return response.data;
	}
}
