import { Format } from "@cruncho/cruncho-shared-types";

import Button from "@mui/material/Button";

import { useCallback, useEffect, useState } from "react";
import { api } from "services/api";
import { CreateFormatDialog } from "./CreateFormatDialog";
import { EditFormatDialog } from "./EditFormatDialog";

import { FormatList } from "./FormatList";

export function FormatsView() {
	const [formats, setFormats] = useState<Format[]>([]);

	const fecthAllFormats = useCallback(async () => {
		const all_formats = await api.format.getAll();

		setFormats(
			all_formats.sort((format1, format2) =>
				format1.slug.localeCompare(format2.slug),
			),
		);
	}, []);

	/**
	 * Fetching all at startup
	 * */
	useEffect(() => {
		fecthAllFormats();
	}, [fecthAllFormats]);

	const [openEdit, setOpenEdit] = useState(false);

	/**
	 * An object containing information about the current selected format
	 */
	const [editingFormat, setEditingFormat] = useState<{
		/**
		 * data related to the selected format.
		 * If undefined, it means that we don't want to edit a format, but to create a new one
		 */
		format?: Format;
	}>();

	/**
	 * Triggered when a format is selected for edition
	 */
	const handleEditFormat = (format?: Format) => {
		setEditingFormat({ format });
		setOpenEdit(true);
	};

	/**
	 * Triggered when creating a format
	 */
	const handleAddFormat = () => {
		setEditingFormat({
			format: undefined,
		});
		setOpenEdit(true);
	};

	/**
	 * Triggered after a format was saved in a modal
	 */
	const handleSaveFormat = async () => {
		// better job could be done here by checking which format has changed
		// and avoid requesting everything back again

		await fecthAllFormats();
	};

	/**
	 * Triggered after a format was created in a modal
	 */
	const handleCreateFormat = async () => {
		// better job could be done here by checking the created format and append it to the correct list,
		// and avoid requesting everything back again

		await fecthAllFormats();
	};

	return (
		<div className="p-4">
			<Button
				variant="contained"
				color="primary"
				onClick={() => {
					handleAddFormat();
				}}
				className="mb-4"
			>
				Create new Format
			</Button>
			<FormatList formats={formats} onFormatEditSelected={handleEditFormat} />
			{/* if the current format is undefined, it means we are in creation mode else, it's edit mode */}
			{editingFormat?.format ? (
				<EditFormatDialog
					open={openEdit}
					format={editingFormat.format}
					onClose={() => setOpenEdit(false)}
					onSave={async () => {
						await handleSaveFormat();
						setOpenEdit(false);
						setEditingFormat(undefined);
					}}
				/>
			) : (
				<CreateFormatDialog
					open={openEdit}
					onClose={() => setOpenEdit(false)}
					onSave={async () => {
						await handleCreateFormat();
						setOpenEdit(false);
						setEditingFormat(undefined);
					}}
				/>
			)}
		</div>
	);
}
