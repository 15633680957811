import TextField, { TextFieldProps } from "@mui/material/TextField";

const StyledReadOnlyTextField = (props: TextFieldProps) => {
	const { InputProps, ...deconstructedProps } = props;
	return (
		<TextField
			fullWidth
			variant="standard"
			InputProps={{
				...{ readOnly: true, disableUnderline: true },
				...InputProps,
			}}
			{...deconstructedProps}
		/>
	);
};
export default StyledReadOnlyTextField;
