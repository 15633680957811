import { AutocompleteField, SelectField, TextField } from "@cruncho/components";
import { ALL_APIS, CategoryMapping } from "@cruncho/cruncho-shared-types";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Form } from "formik";
import { useCallback, useEffect, useState } from "react";

import { api } from "../../services/api";

type CreateOrEditCategoryMappingFormProps = {
	/**
	 * True if any input has been touched. False otherwise.
	 */
	dirty: boolean;
	/**
	 * Whether the form is currently submitting
	 */
	isSubmitting: boolean;
	/**
	 * Mapping to edit. Use an empty mapping if none is provided
	 */
	mapping?: CategoryMapping;
	/**
	 * Function called on close (without saving document)
	 */
	onClose: () => void;
};

/**
 * A form to create or edit an existing category mapping.
 * If no mapping is provided, considered as a creation
 */
export function CreateOrEditCategoryMappingForm({
	dirty,
	isSubmitting,
	mapping,
	onClose,
}: CreateOrEditCategoryMappingFormProps) {
	const [l3SlugsListAndNullAndToEdit, setL3SlugsListAndNullAndToEdit] = useState<
		Array<string>
	>([]);
	const [destinationSlugs, setDestinationSlugs] = useState<Array<string>>([]);

	/**
	 * Fetch l3 slugs
	 */
	const fetchAllL3Slugs = useCallback(async () => {
		const fetchL3Slugs = await api.category.l3.getAll();
		setL3SlugsListAndNullAndToEdit(
			fetchL3Slugs.map((l3) => l3.slug).concat(["to_edit"]),
		);
	}, []);

	const fetchAllDestinationSlugs = useCallback(async () => {
		const fetchDestinationSlugs = await api.destination.getAll();
		setDestinationSlugs(
			["All destinations"].concat(
				fetchDestinationSlugs.map((destination) => destination._id).sort(),
			),
		);
	}, []);

	useEffect(() => {
		fetchAllL3Slugs();
		fetchAllDestinationSlugs();
	}, [fetchAllL3Slugs, fetchAllDestinationSlugs]);

	return (
		<Form>
			<DialogTitle>
				{mapping ? `Edit ${mapping.api}/${mapping.apiCategoryId}` : "Create"}{" "}
				Category Mapping
			</DialogTitle>

			<DialogContent>
				<Grid container spacing={2}>
					<Grid item container spacing={2}>
						<Grid item xs={6}>
							<Typography gutterBottom variant="h6">
								External API
							</Typography>{" "}
						</Grid>
					</Grid>
					<Grid item container spacing={2}>
						<Grid item xs={6}>
							<SelectField
								size="small"
								fullWidth
								label="External API"
								name="api"
								items={ALL_APIS.map((api_name) => ({
									value: api_name,
									label: api_name,
								}))}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								name="apiCategoryId"
								label="External API category identifier"
								variant="standard"
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								name="apiCategoryName"
								label="External API category name (optional)"
								variant="standard"
							/>
						</Grid>
					</Grid>
					<Grid item container spacing={2}>
						<Grid item xs={6}>
							<Typography gutterBottom variant="h6">
								Our API
							</Typography>{" "}
						</Grid>
					</Grid>
					<Grid item container spacing={2}>
						<Grid item xs={6}>
							<AutocompleteField
								name="crunchoSlug"
								textFieldProps={{
									label: "Cruncho equivalent",
								}}
								options={l3SlugsListAndNullAndToEdit}
							/>
						</Grid>
						<Grid item xs={6}>
							<AutocompleteField
								name="destinationSlug"
								textFieldProps={{
									label: "Destination",
								}}
								options={destinationSlugs}
								defaultValue={"All destinations"}
							/>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose} color="primary">
					Cancel
				</Button>
				{dirty && (
					<Button
						type="submit"
						color="secondary"
						variant="outlined"
						disabled={isSubmitting}
					>
						Save
					</Button>
				)}
			</DialogActions>
		</Form>
	);
}
