import { Practitioner } from "@cruncho/cruncho-shared-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import { FAQSectionItem } from "./FAQSectionItem";

interface FAQSectionProps {
	practitionerData: Practitioner;
	practitionerId: string;
}

export function FAQSection({
	practitionerData,
	practitionerId,
}: FAQSectionProps) {
	return (
		<Card>
			<CardHeader title="FAQ section" />
			<CardContent>
				<List>
					{practitionerData.FAQSection &&
						practitionerData.FAQSection.map((_, index) => (
							<>
								<FAQSectionItem
									FAQSectionData={practitionerData.FAQSection!}
									index={index}
									practitionerId={practitionerId}
									key={`FAQItem_${index}`}
								/>
								<Divider
									style={{
										marginTop: 15,
										marginBottom: 20,
									}}
								/>
							</>
						))}
				</List>
			</CardContent>
		</Card>
	);
}
