import { ClipboardCopyButton } from "@cruncho/components";
import {
	cityFeaturesSchema,
	Destination,
	destinationSchema,
	eventManagerFeaturesSchema,
} from "@cruncho/cruncho-shared-types";
import { fillCityWithDefaultFields } from "@cruncho/utils/helpers";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import axios, { AxiosError } from "axios";

import { Form, Formik, FormikConfig, prepareDataForValidation } from "formik";
import { useSnackbar } from "notistack";
import { createContext, useContext, useEffect, useState } from "react";
import { api } from "services/api";
import { z, ZodSchema } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

/**
 * Custom context to edit the current schema
 */
export const SchemaConfigContext = createContext<
	SchemaConfigContextInterface | undefined
>(undefined);

/**
 * Values provided by the custom schema context
 */
export type SchemaConfigContextInterface = {
	destination: Destination;
	schema: ZodSchema<any>;
	enableEventManager: boolean;
	enablePhoneFormat: boolean;
	setEnableEventManager: (enabled: boolean) => void;
	setEnablePhoneFormat: (enabled: boolean) => void;
};

/**
 * Helper function to get the current context.
 * @throws if not called from within the FormikDestinationWrapper component
 */
export const useSchemaConfig = (): SchemaConfigContextInterface => {
	const context = useContext(SchemaConfigContext);

	if (context === undefined) {
		throw new Error("You must use this component in a context provider");
	}

	return context;
};

type FormikDestinationWrapperProps = {
	/**
	 * Children of this wrapper that will be able to access formik context
	 */
	children: FormikConfig<Destination>["children"];
	/**
	 * The destination to edit
	 */
	destination: Destination;
	/**
	 * Triggered when clicking the save button
	 */
	onSave: (updatedDestination: Destination) => void;
};

/**
 * A wrapper providing a formik context for form handling and a custom context for schema modification.
 *
 * The custom contexts allows to toogle booleans to activate or not part of the schema
 * This automatically update the edited destination and the schema used for form validation
 *
 */
export function FormikDestinationWrapper({
	children,
	destination,
	onSave,
}: FormikDestinationWrapperProps) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	// The validation schema. It can be modified afterward
	const [schema, setSchema] = useState(destinationSchema);

	const [initialValues, setInitialValues] = useState(
		fillCityWithDefaultFields(destination),
	);

	/**
	 * Booleans allowing to deactivate parts of the schema
	 */

	const [enableEventManager, setEnableEventManager] = useState(
		Boolean(destination.features.eventManagerFeatures),
	);
	const [enablePhoneFormat, setEnablePhoneFormat] = useState(
		Boolean(destination.features.eventManagerFeatures?.phoneFormat),
	);

	// refreshing the toggles when the destination changes
	useEffect(() => {
		setEnableEventManager(Boolean(destination.features.eventManagerFeatures));
		setEnablePhoneFormat(
			Boolean(destination.features.eventManagerFeatures?.phoneFormat),
		);
	}, [destination]);

	// Changing the schema to take into account toggled fields (see EventManager Switches)
	useEffect(() => {
		// deactivating fields if they were not chosen by the user

		if (!enableEventManager) {
			const newSchema = destinationSchema.merge(
				z.object({
					features: cityFeaturesSchema.omit({ eventManagerFeatures: true }),
				}),
			) as any;

			setSchema(newSchema);
			return;
		}

		let newEventManagerFeaturesSchema = eventManagerFeaturesSchema;

		if (!enablePhoneFormat) {
			newEventManagerFeaturesSchema = newEventManagerFeaturesSchema.omit({
				phoneFormat: true,
			}) as any;
		}

		const newFeaturesSchema = cityFeaturesSchema.merge(
			z.object({ eventManagerFeatures: newEventManagerFeaturesSchema }),
		);

		const newSchema = destinationSchema.merge(
			z.object({
				features: newFeaturesSchema,
			}),
		) as any;

		setSchema(newSchema);
	}, [enableEventManager, enablePhoneFormat]);

	/**
	 * Sanitizing the initial values when the input destination changes
	 */
	useEffect(() => {
		const newInitialValues = fillCityWithDefaultFields(destination);

		const sanitizedNewInitalValuesValidator = schema.safeParse(newInitialValues);

		// Trying to parse the initial values when they arrive
		if (sanitizedNewInitalValuesValidator.success) {
			setInitialValues(sanitizedNewInitalValuesValidator.data);
		} else {
			// if it fails, we still use those values to allow the user to fix them

			setInitialValues(newInitialValues);
		}
	}, [schema, destination]);

	return (
		/**
		 * We first provide the custom context to be able to edit the current schema
		 */
		<SchemaConfigContext.Provider
			value={{
				schema,
				destination,
				enableEventManager,
				enablePhoneFormat,
				setEnableEventManager,
				setEnablePhoneFormat,
			}}
		>
			{/*
				Then we provide the Formik schema that uses our custom schema
			 */}

			<Formik
				enableReinitialize={true}
				validationSchema={toFormikValidationSchema(schema)}
				initialValues={initialValues}
				onSubmit={async (values) => {
					const preparedData = prepareDataForValidation(values);

					try {
						// parsing city object to protect the database
						const parsedCity = schema.parse(preparedData);

						// closing all potential errors
						closeSnackbar();

						const updatedDestination = await api.destination.update(
							parsedCity._id,
							parsedCity,
						);

						// The backend returns a city with categories. To be safe, parsing it again because we only want a DatabaseCity...

						const parseUpdatedDestination =
							destinationSchema.safeParse(updatedDestination);
						if (parseUpdatedDestination.success) {
							onSave(parseUpdatedDestination.data);

							// displaying success
							enqueueSnackbar(`${destination._id} updated!`, {
								variant: "success",
							});
						} else {
							throw parseUpdatedDestination.error;
						}
					} catch (error) {
						console.error(error);
						if (
							axios.isAxiosError(error) &&
							(error as AxiosError<{ message: string }>).response?.data?.message
						) {
							enqueueSnackbar(
								(error as AxiosError<{ message: string }>).response?.data?.message,
								{
									variant: "error",
									persist: true,
								},
							);
						} else {
							enqueueSnackbar(String(error), { variant: "error", persist: true });
						}
					}
				}}
			>
				{({ dirty, isValid, values, errors, ...props }) => (
					<Form>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Grid container alignItems="center" justifyContent="space-between">
									<Grid item>
										<Typography variant="body2">
											If you edit a tab, save your work before moving to another one
										</Typography>
									</Grid>

									<Stack direction="row">
										<Button
											type="submit"
											aria-label="save changes"
											variant="contained"
											onClick={() => {
												Object.entries(errors).forEach((error) => {
													enqueueSnackbar(JSON.stringify(error, null, 2), {
														variant: "error",
														persist: true,
													});
													console.error(error);
												});
											}}
											color={isValid ? "primary" : "error"}
											size="medium"
										>
											{isValid ? "Save Changes" : "There are errors"}
										</Button>
										<ClipboardCopyButton
											data={values}
											aria-label="destination copied to clipboard"
											title="Copy city features"
											onCopyDone={() => {
												enqueueSnackbar("Destination Copied to Clipboard", {
													variant: "info",
												});
											}}
										/>
									</Stack>
								</Grid>
							</Grid>

							<Grid item xs={12}>
								{typeof children === "function"
									? children({ dirty, isValid, values, errors, ...props })
									: children}
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</SchemaConfigContext.Provider>
	);
}
