import {
	SoulcircusProduct,
	SoulcircusProductSugarcoated,
} from "@cruncho/cruncho-shared-types";
import { APIBase } from "services/APIBase";

export class APIProduct extends APIBase {
	async getAll() {
		const response = await this.api.get<SoulcircusProduct[]>(
			"/soulcircus/product/",
		);
		return response.data;
	}

	async update(productId: string, product: SoulcircusProductSugarcoated) {
		const response = await this.api.put<SoulcircusProduct>(
			`/soulcircus/product/${productId}`,
			{ product },
		);
		return response.data;
	}

	async create(product: SoulcircusProductSugarcoated) {
		const response = await this.api.post<SoulcircusProduct>(
			"/soulcircus/product/",
			{ product },
		);
		return response.data;
	}

	async delete(productId: string | undefined) {
		const response = await this.api.delete<SoulcircusProduct>(
			`/soulcircus/product/${productId}`,
		);

		return response.data;
	}
}
