import {
	StringSelectWithDescriptionField,
	NumberWithDescriptionField,
	StringWithDescriptionField,
	BooleanWithDescriptionField,
	L1DependantBooleanWithDescriptionField,
	FilterBooleanWithDescriptionField,
} from "@cruncho/components";
import { crunchoLanguageAnalyzers } from "@cruncho/cruncho-shared-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

/**
 * Section of fields related to General info of the features and hooked to formik
 */
export function GeneralSection() {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h6">General</Typography>
			</Grid>

			<Grid item xl={4} lg={6} xs={12}>
				<StringSelectWithDescriptionField
					name="Default section"
					arrayOptions={[
						"see-and-do",
						"eat-and-drink",
						"hotels",
						"live-events",
						"topPicks",
					]}
					description="This sets the default section of your guide that users land on."
					databaseName="features.defaultResultRoute"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<NumberWithDescriptionField
					name="Recommendations per page"
					databaseName="features.fixedRecosNumber"
					description="This sets the number of recommendations to display per page."
					minValue={1}
					step={1}
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<StringWithDescriptionField
					name="Logo link URL"
					description="This is the link to which users are directed to when clicking on the top left logo."
					databaseName="features.topLeftLogoLinkURL"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<StringWithDescriptionField
					name="Sponsored text"
					description="This is the text displayed on sponsored cards."
					databaseName="features.customSponsoredText"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<StringWithDescriptionField
					name="Tracking code TheFork"
					description="This is the affiliation tracking code used for TheFork's recommendations."
					databaseName="features.forkTrackingCode"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<StringWithDescriptionField
					name="GA Property key (old guide)"
					description="This is the property key used on the old guide for different destinations and separating them in Google Analytics"
					databaseName="features.gaTrackingCode"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<StringWithDescriptionField
					name="GA4 Property key (new guide)"
					description="This is the property key used on the new guide for different destinations and separating them in Google Analytics (it has to be a Google Analytics 4 key)"
					databaseName="features.ga4TrackingCode"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Weekly recommendation emails"
					description="When activated, the users who liked a reco or a carousel on this destination will receive suggestions by mail every week."
					databaseName="features.weeklyRecoMail"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name='Emphasize "Publish your event" button'
					description='When activated, the "Publish your event" button will be emphasized'
					databaseName="features.emphasizePublishYourEvent"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Hour format"
					description="When activated, the hour format is a 12h format. Otherwise, it is a 24h format."
					databaseName="features.hours12"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Show Event Format in guides and EM"
					description="When activated, all formats will be shown in the eventmanager and in the guides as carousel and format page."
					databaseName="features.showFormat"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Show onboarding modal"
					description="When activated, the onboarding module is shown when a user visits the guide for the first time."
					databaseName="features.showOnBoardingModal"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Learn more buttons"
					description="If activated, the 'Book' buttons will be replaced by 'Learn more' buttons when possible."
					databaseName="features.useLearnMoreAsBookButton"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Distances in miles"
					description="When activated, distances are shown in miles instead of meters."
					databaseName="features.useMiles"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="US flag"
					description="When activated, the UK flag for english language is replaced by a US flag."
					databaseName="features.useUSFlag"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Hide login"
					description="When activated, the Cruncho login is not visible anymore on the guide."
					databaseName="features.hideSidebarLogin"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<NumberWithDescriptionField
					name="Maximum matching distance"
					databaseName="features.maximumMatchingDistance"
					description="Value in meters to say to the script how far it should match recos. E.g: Skaraborg: works ~1.5km, but not stockholm"
					minValue={1}
					step={1}
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<StringSelectWithDescriptionField
					name="Language analyzer"
					databaseName="features.elasticMatchingAnalyzer"
					description="Select the analyzer used for the matching algorithm"
					arrayOptions={crunchoLanguageAnalyzers.map((analyzer) => analyzer.value)}
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<FilterBooleanWithDescriptionField
					name="Hide filters"
					description="When activated for a filter, it is hidden for all the categories: Visit, Eat&drink, Hotels and Events."
					databaseName="features.hideFilters"
				/>
			</Grid>
			<Grid item container spacing={2} xl={4} lg={6} xs={12} className="h-fit">
				<Grid item xs={12}>
					<BooleanWithDescriptionField
						name="Hide handpicked carousel"
						description="When activated, the handpicked carousel is hidden."
						databaseName="features.hideHandpickedCarousel"
					/>
				</Grid>
				<Grid item xs={12}>
					<StringWithDescriptionField
						name="Your guide's URL"
						description="The 'See all' button directs users to this URL. This URL should normally link to the page on your website where the Cruncho Guide iFrame is installed."
						databaseName="features.seeAllIframeURL"
					/>
				</Grid>
				<Grid item xs={12}>
					<L1DependantBooleanWithDescriptionField
						name="Hide bookable carousel"
						description="When activated for an L1, the corresponding bookable carousel is hidden."
						databaseName="features.hideBookableCarousel"
						isCarousel
					/>
				</Grid>
			</Grid>
		</Grid>
	);
}
