import { CategoryMapping } from "@cruncho/cruncho-shared-types";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";

type CategoryMappingListItemProps = {
	/**
	 * Category mapping
	 */
	mapping: CategoryMapping;
	/**
	 * A callback triggered when clicking the delete button
	 */
	onDeleteSelected: (mapping: CategoryMapping) => void;
	/**
	 * A callback triggered when clicking the edit button
	 */
	onEditSelected: (mapping: CategoryMapping) => void;
};

/**
 * A component to diplay one category in a list row
 */
export function CategoryMappingListItem({
	mapping,
	onDeleteSelected,
	onEditSelected,
}: CategoryMappingListItemProps) {
	const [openDeleteWarningDialog, setOpenDeleteWarningDialog] = useState(false);

	return (
		<ListItem>
			<ListItemText
				primary={mapping.apiCategoryId}
				secondary={`API: ${mapping.api} - Cruncho slug: ${
					mapping.crunchoSlug
				} - Destination: ${mapping.destinationSlug ?? "All destinations"}`}
			/>

			<IconButton
				title="Edit this category mapping"
				aria-label="edit this category mapping"
				edge="end"
				onClick={() => {
					onEditSelected(mapping);
				}}
				size="large"
				sx={{ ":hover": { color: "primary.main" } }}
			>
				<EditIcon />
			</IconButton>
			<IconButton
				title="Delete this category mapping"
				aria-label="delete this category mapping"
				edge="end"
				onClick={() => setOpenDeleteWarningDialog(true)}
				size="large"
				sx={{ ":hover": { color: "red" } }}
			>
				<DeleteIcon />
			</IconButton>
			<Dialog
				open={openDeleteWarningDialog}
				onClose={() => setOpenDeleteWarningDialog(false)}
			>
				<DialogTitle id="alert-dialog-title">
					Delete this Category Mapping?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						If you delete this category mapping, it will be erased from the database.
						There is no way to retrieve it.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setOpenDeleteWarningDialog(false)}
						color="error"
						variant="outlined"
						aria-label="cancel"
					>
						Cancel
					</Button>
					<Button
						onClick={() => onDeleteSelected(mapping)}
						autoFocus
						color="success"
						variant="outlined"
						aria-label="delete"
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</ListItem>
	);
}
