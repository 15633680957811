import { Area, Destination } from "@cruncho/cruncho-shared-types";
import Typography from "@mui/material/Typography";
import { FormikDestinationWrapper } from "../FormikDestinationWrapper";
import { AreaEditor } from "./AreaEditor";
import { AreasEditor } from "./AreasEditor";

type GeographyTabProps = {
	/**
	 * The destination to edit
	 */
	destination: Destination;
	/**
	 * Triggered when clicking on save button
	 */
	onSave: (updatedDestination: Destination) => void;
};

/**
 * A tab displaying allowing edition of areas related info of a destination
 */
export function GeographyTab({ onSave, destination }: GeographyTabProps) {
	const globalArea: Area = {
		name: "Global",
		label: "Global bundle geography",
		polygon: {
			type: "Polygon",
			coordinates: destination.geography.geoPolygon.coordinates,
		},
	};

	return (
		<>
			<Typography variant="h4">Global bundle geography</Typography>
			<FormikDestinationWrapper onSave={onSave} destination={destination}>
				{({ values, setFieldValue }) => (
					<AreaEditor
						isGlobal
						area={globalArea}
						defaultCenter={values.geography.geoCenterPt}
						onChange={(area) => {
							setFieldValue("geography.geoPolygon", area.polygon);
						}}
					/>
				)}
			</FormikDestinationWrapper>
			<Typography variant="h4">Areas editor</Typography>
			<FormikDestinationWrapper onSave={onSave} destination={destination}>
				{({ values, setFieldValue }) => (
					<AreasEditor
						areas={values.areas ?? []}
						defaultCenter={values.geography.geoCenterPt}
						onAreasChange={(areas) => {
							setFieldValue("areas", areas);
						}}
					/>
				)}
			</FormikDestinationWrapper>
		</>
	);
}
