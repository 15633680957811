import { StringSelectWithDescriptionField } from "@cruncho/components";
import {
	MAP_PROVIDERS_OPTIONS,
	ANALYTICS_PROVIDERS_OPTIONS,
	AUTOCOMPLETE_PROVIDERS_OPTIONS,
	STORAGE_PROVIDERS_OPTIONS,
} from "@cruncho/cruncho-shared-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export function ServiceProvidersSection() {
	return (
		<section>
			<Typography variant="h6" gutterBottom>
				{"Service Providers"}
			</Typography>
			<Grid container spacing={1}>
				<Grid item xs={12} md={6}>
					<StringSelectWithDescriptionField
						databaseName="features.serviceProviders.map"
						name="Map Provider"
						description="Select the service to use the maps providers"
						arrayOptions={[...MAP_PROVIDERS_OPTIONS]}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<StringSelectWithDescriptionField
						databaseName="features.serviceProviders.analytics"
						name="Analytics Provider"
						description="Select the service to use the analytics providers"
						arrayOptions={[...ANALYTICS_PROVIDERS_OPTIONS]}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<StringSelectWithDescriptionField
						databaseName="features.serviceProviders.autocomplete"
						name="Autocomplete Provider"
						description="Select the service to use the autocomplete providers (we only use google autocomplete but in case some clients complains about requests, defaultAC is an alias of google)"
						arrayOptions={[...AUTOCOMPLETE_PROVIDERS_OPTIONS]}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<StringSelectWithDescriptionField
						databaseName="features.serviceProviders.storage"
						name="Storage Provider"
						description="Select the service to use the storage providers"
						arrayOptions={[...STORAGE_PROVIDERS_OPTIONS]}
					/>
				</Grid>
			</Grid>
		</section>
	);
}
