import { PractitionerCard } from "@cruncho/cruncho-shared-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import axios, { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { api } from "services/api";

type DeletePractitionerDialogProps = {
	onClose: () => void;
	open: boolean;
	practitioner: PractitionerCard;
};

export function DeletePractitionerDialog({
	onClose,
	open,
	practitioner,
}: DeletePractitionerDialogProps) {
	const { enqueueSnackbar } = useSnackbar();

	const onDeleteClick = async () => {
		try {
			const res = await api.practitioner.deletePractitioner(practitioner._id!);

			if (res)
				enqueueSnackbar(`${practitioner.firstname} deleted! - ${res}`, {
					variant: "success",
				});
		} catch (error: any) {
			console.error(error);
			if (
				axios.isAxiosError(error) &&
				(error as AxiosError<{ message: string }>).response?.data?.message
			) {
				enqueueSnackbar(
					(error as AxiosError<{ message: string }>).response?.data?.message,
					{
						variant: "error",
						persist: true,
					},
				);
			} else {
				enqueueSnackbar(JSON.stringify(error, null, 2), {
					variant: "error",
					persist: true,
				});
			}
		}
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle>Delete this practitioner ?</DialogTitle>
			<DialogContent>
				{`Do you really want delete the following practitioner: ${practitioner.firstname} ${practitioner.lastname} ?`}
			</DialogContent>
			<DialogActions>
				<Button
					type="submit"
					color="secondary"
					variant="outlined"
					onClick={onDeleteClick}
				>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
}
