import { AxiosInstance } from "axios";
import { APIBase } from "../APIBase";
import { APIL1 } from "./l1";
import { APIL2 } from "./l2";
import { APIL3 } from "./l3";
import { APIMappings } from "./mappings";

/**
 * A class handling the interractions with category-related endpoints in the api
 * You should use it through the l1, l2, l3 members
 *
 * @example
 *
 * api.category.l1.get("anId")
 */
export class APICategory extends APIBase {
	l1: APIL1;

	l2: APIL2;

	l3: APIL3;

	mappings: APIMappings;

	constructor(api: AxiosInstance) {
		super(api);
		this.l1 = new APIL1(api);
		this.l2 = new APIL2(api);
		this.l3 = new APIL3(api);
		this.mappings = new APIMappings(api);
	}
}
