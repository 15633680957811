import { Destination } from "@cruncho/cruncho-shared-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { EventManagerTab } from "./EventManagerTab";
import { FeaturesTab } from "./FeaturesTab";
import { GeneralTab } from "./GeneralTab";
import { GeographyTab } from "./GeographyTab";

/**
 * The list of sections in which we group the "modules" (city features attributes)
 * */

interface DestinationDetailProps {
	/**
	 * The destination to display
	 */
	destination: Destination;
	/**
	 * A callback triggered when saving the current destination
	 */
	handleDestinationUpdate: (updatedDestination: Destination) => void;
}

/**
 * A component allowing to edit the details of a destination;
 *
 */
export function DestinationDetail({
	destination,
	handleDestinationUpdate,
}: DestinationDetailProps) {
	// Tracking the current tab index to display
	const [currentTabIndex, setCurrentTabIndex] = useState(0);

	return (
		<Stack spacing={2}>
			<Card>
				<CardContent>
					<Grid container justifyContent="space-between">
						<Grid item>
							<Typography variant="h4">{`Edit: ${destination._id}`}</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</Card>

			<Tabs
				value={currentTabIndex}
				onChange={(_, tabIndex) => setCurrentTabIndex(tabIndex)}
				centered
			>
				<Tab label="General" />
				<Tab label="Geography" />
				<Tab label="Features" />
				<Tab label="EventManager" />
			</Tabs>

			{currentTabIndex === 0 && (
				<GeneralTab onSave={handleDestinationUpdate} destination={destination} />
			)}
			{currentTabIndex === 1 && (
				<GeographyTab onSave={handleDestinationUpdate} destination={destination} />
			)}
			{currentTabIndex === 2 && (
				<FeaturesTab onSave={handleDestinationUpdate} destination={destination} />
			)}
			{currentTabIndex === 3 && (
				<EventManagerTab
					onSave={handleDestinationUpdate}
					destination={destination}
				/>
			)}
		</Stack>
	);
}
