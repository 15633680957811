import { CategoryMapping } from "@cruncho/cruncho-shared-types";
import Button from "@mui/material/Button";
import { useCallback, useEffect, useState } from "react";

import { api } from "../../services/api";
import { CategoryMappingList } from "./CategoryMappingList";
import { CreateCategoryMappingDialog } from "./CreateCategoryMappingDialog";
import { EditCategoryMappingDialog } from "./EditCategoryMappingDialog";

export function CategoryMappingView() {
	const [categoryMappings, setCategoryMappings] = useState<
		Array<CategoryMapping>
	>([]);

	/**
	 * Keeping track of the selected category in each list
	 */
	const [selectedCategoryMapping, setSelectedCategoryMapping] =
		useState<CategoryMapping>();

	/**
	 * Fetch category mappings
	 */
	const fetchAllCategoryMappings = useCallback(async () => {
		const fetchCategoryMappings = await api.category.mappings.getAll();
		setCategoryMappings(fetchCategoryMappings);
	}, []);

	useEffect(() => {
		fetchAllCategoryMappings();
	}, [fetchAllCategoryMappings]);

	/**
	 * Used to open the modals
	 */
	const [openCreateModal, setOpenCreateModal] = useState(false);
	const [openEditModal, setOpenEditModal] = useState(false);

	/**
	 * Triggered after a category mapping was edited (and saved) in a modal
	 */
	const handleEditCategoryMapping = async (mapping: CategoryMapping) => {
		const newCategoryMappings = categoryMappings.map((mapp) =>
			mapp._id === mapping._id ? mapping : mapp,
		);
		setCategoryMappings(newCategoryMappings);
	};

	/**
	 * Triggered after a category mapping was created (and saved) in a modal
	 */
	const handleCreateCategoryMapping = async (mapping: CategoryMapping) => {
		const newCategoryMappings = categoryMappings.concat([mapping]);
		setCategoryMappings(newCategoryMappings);
	};

	/**
	 * Triggered when clicking on the "delete" button on an element of the mappings list
	 */
	const deleteCategoryMapping = async (mapping: CategoryMapping) => {
		const deletedCategoryMapping = await api.category.mappings.delete(
			mapping._id,
		);
		const newCategoryMappings = categoryMappings.filter(
			(mapp) => mapp._id !== deletedCategoryMapping._id,
		);
		setCategoryMappings(newCategoryMappings);
	};

	return (
		<div className="p-4">
			<Button
				aria-label="create a new category mapping"
				variant="contained"
				color="primary"
				onClick={() => {
					setOpenCreateModal(true);
				}}
				className="mb-4"
			>
				Create a new category mapping
			</Button>

			<CategoryMappingList
				categoryMappings={categoryMappings}
				onCategoryMappingDeleteSelected={(_mapping: CategoryMapping) => {
					deleteCategoryMapping(_mapping);
				}}
				onCategoryMappingEditSelected={(_mapping: CategoryMapping) => {
					setSelectedCategoryMapping(_mapping);
					setOpenEditModal(true);
				}}
			/>

			{selectedCategoryMapping && (
				<EditCategoryMappingDialog
					open={openEditModal}
					mapping={selectedCategoryMapping}
					onClose={() => setOpenEditModal(false)}
					onSave={async (_mapping: CategoryMapping) => {
						await handleEditCategoryMapping(_mapping);
						setOpenEditModal(false);
						setSelectedCategoryMapping(undefined);
					}}
				/>
			)}

			<CreateCategoryMappingDialog
				open={openCreateModal}
				onClose={() => setOpenCreateModal(false)}
				onSave={async (_mapping: CategoryMapping) => {
					await handleCreateCategoryMapping(_mapping);
					setOpenCreateModal(false);
					setSelectedCategoryMapping(undefined);
				}}
			/>
		</div>
	);
}
