import CloseIcon from "@mui/icons-material/Close";
import DialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import * as React from "react";

export interface DialogTitleWithCloseIconProps extends DialogTitleProps {
	children?: React.ReactNode;
	onClose: () => void;
}

/**
 * DialogTitle with a close icon on the right when the `onClose` callback is provided.
 */
export function DialogTitleWithCloseIcon({
	children,
	onClose,
	...other
}: DialogTitleWithCloseIconProps) {
	return (
		<DialogTitle
			sx={(theme) => ({
				"& .MuiDialogContent-root": {
					padding: theme.spacing(2),
				},
				"& .MuiDialogActions-root": {
					padding: theme.spacing(1),
				},
				m: 0,
				p: 2,
			})}
			{...other}
		>
			{children}

			{onClose && (
				<IconButton
					aria-labelledby="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			)}
		</DialogTitle>
	);
}
