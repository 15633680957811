import ContentPaste from "@mui/icons-material/ContentPaste";
import IconButton from "@mui/material/IconButton";

export interface CopyTextButtonProps {
	text: string;
}

/**
 * A Clipboard, which copy `text` into the clipboard of the user on click.
 */
export function CopyTextButton({ text }: CopyTextButtonProps) {
	return (
		<IconButton
			style={{
				height: "fit-content",
			}}
			onClick={() => {
				try {
					if (navigator.clipboard) {
						navigator.clipboard
							.writeText(text)
							.then(() => alert("Copied from clipboard"))
							.catch((err) => console.error(err));
					} else {
						const elem = document.createElement("textarea");
						elem.value = text;
						document.body.appendChild(elem);
						elem.select();
						// eslint-disable-next-line deprecation/deprecation
						document.execCommand("copy", false, text);
						document.body.removeChild(elem);
						alert("Copied from document");
					}
				} catch (e) {
					console.error(e);
					const elem = document.createElement("textarea");
					elem.value = text;
					document.body.appendChild(elem);
					elem.select();
					// eslint-disable-next-line deprecation/deprecation
					document.execCommand("copy", false, text);
					document.body.removeChild(elem);
				}
			}}
		>
			<ContentPaste />
		</IconButton>
	);
}
