import { Category, ChildCategory } from "@cruncho/cruncho-shared-types";
import TrashIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import { CategoryType } from "./types";

type CategoryListItemProps<C extends Category | ChildCategory> = {
	category?: C;
	/**
	 * A callback triggered when clicking the row
	 */
	onSelect: (category?: C) => void;

	/**
	 * A callback triggered when clicking the edit button
	 */
	onEditSelected: (category?: C) => void;
	/**
	 * A callback triggered when clicking the "add-under-this-category" button
	 */
	onAddSelected?: (category?: C) => void;
	onDeleteSelected?: (category?: C) => void;
	selected: boolean;
	categoryType: CategoryType;
};

/**
 * A component to diplay one category in a list row
 */
export function CategoryListItem<C extends Category | ChildCategory>({
	category,
	onSelect: onSelected,
	selected,
	onEditSelected,
	onAddSelected,
	onDeleteSelected,
	categoryType,
}: CategoryListItemProps<C>) {
	return (
		<ListItemButton
			aria-label="list item"
			selected={selected}
			onClick={() => {
				onSelected(category);
			}}
		>
			{category ? (
				categoryType === "L2" ? (
					<ListItemText
						primary={category.slug}
						secondary={`#${category.order ?? "NaN"} ${
							category.default ? "- Default" : ""
						} - ${category.slug}`}
					/>
				) : (
					<ListItemText
						primary={category.slug}
						secondary={`${category.default ? "Default - " : ""}${category.slug}`}
					/>
				)
			) : (
				<ListItemText primary="ALL" />
			)}

			{category?.hideOnEM && (
				<Chip label="Hidden on EM" variant="outlined" color="warning" />
			)}

			{onAddSelected && (
				<IconButton
					title="Add a new child to this category"
					aria-label="add a new child to this category"
					edge="end"
					onClick={() => {
						onAddSelected(category);
					}}
					size="large"
				>
					<PlaylistAddIcon />
				</IconButton>
			)}

			{category && (
				<IconButton
					title="Edit this category"
					aria-label="edit this category"
					edge="end"
					onClick={() => {
						onEditSelected(category);
					}}
					size="large"
				>
					<EditIcon />
				</IconButton>
			)}
			{onDeleteSelected && (
				<IconButton
					title="Delete this category"
					aria-label="delete this category"
					edge="end"
					onClick={() => {
						onDeleteSelected(category);
					}}
					size="large"
				>
					<TrashIcon />
				</IconButton>
			)}
		</ListItemButton>
	);
}
