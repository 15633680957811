import { Category } from "@cruncho/cruncho-shared-types";
import { APIBase } from "../APIBase";

/**
 * A class handling the interractions with l1-related endpoints in the api
 * The returned data are using a "DB Category" type. This is for edition purpose, to avoid breaking those fields.
 * If you don't need to send it back to the server, you should cast this to the regular Category purpose.
 *
 * This way, it will be easier to update the code once those types are dropped
 */
export class APIL1 extends APIBase {
	async get(id: string) {
		const response = await this.api.get<Category>(`/categories/l1/${id}`);
		return response.data;
	}

	async getAll() {
		const response = await this.api.get<Category[]>("/categories/l1");
		return response.data;
	}

	async update(id: string, category: Category) {
		const response = await this.api.put<Category>(
			`/categories/l1/${id}`,
			category,
		);
		return response.data;
	}
}
