import {
	ALL_APIS,
	AvailableApi,
	CategoryMapping,
} from "@cruncho/cruncho-shared-types";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { FixedSizeList } from "react-window";

import { CategoryMappingListItem } from "./CategoryMappingListItem";

type CategoryMappingListProps = {
	/**
	 * The list of categories to display
	 */
	categoryMappings: Array<CategoryMapping>;
	/**
	 * Triggered when clicking on the button to delete a category
	 */
	onCategoryMappingDeleteSelected: (mapping: CategoryMapping) => void;
	/**
	 * Triggered when clicking on the button to edit a category
	 */
	onCategoryMappingEditSelected: (mapping: CategoryMapping) => void;
};

/**
 * A component displaying a list of category mappings that can be filtered by api, cruncho_slug,
 * api_category_id or api_name.
 *
 */
export function CategoryMappingList({
	categoryMappings,
	onCategoryMappingDeleteSelected,
	onCategoryMappingEditSelected,
}: CategoryMappingListProps) {
	/**
	 * A string used to filter the displayed category mappings
	 */
	const [searchQuery, setSearchQuery] = useState<string>("");

	/**
	 * To filter category mappings by API
	 */
	const [selectedAPI, setSelectedAPI] = useState<AvailableApi | "All">("All");

	/**
	 * The subset of category mappings kept while filtering
	 */
	const [filteredCategoryMappings, setFilteredCategoryMappings] = useState<
		Array<CategoryMapping>
	>([]);

	/**
	 * Search by api, cruncho_slug and api_category_id
	 */
	useEffect(() => {
		/**
		 * Check if a category mapping is from the selected API.
		 * If selected API is "All", return true.
		 *
		 * @param mapping Checked category mapping
		 * @returns true if the category mapping should be kept by API filter, else false
		 */
		const correctAPI = (mapping: CategoryMapping) =>
			selectedAPI === "All" || mapping.api === selectedAPI;

		/**
		 * Check if category mapping api, slug, api id or api name contains the search query.
		 * Spaces in the search string ar considered as "AND" operators
		 *
		 * @param mapping Checked category mapping
		 * @returns true if the category mapping should be kept by query filter, else false
		 */
		const includesSearchQuery = (mapping: CategoryMapping) => {
			const terms = searchQuery.split(" ");
			return terms.every(
				(term) =>
					mapping.api.includes(term) ||
					mapping.crunchoSlug.includes(term) ||
					mapping.apiCategoryId.includes(term) ||
					mapping.destinationSlug?.includes(term),
			);
		};

		if (searchQuery || selectedAPI !== "All") {
			setFilteredCategoryMappings(
				categoryMappings.filter(
					(mapping) => correctAPI(mapping) && includesSearchQuery(mapping),
				),
			);
		} else {
			setFilteredCategoryMappings(categoryMappings);
		}
	}, [categoryMappings, searchQuery, selectedAPI]);

	return (
		<Card>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<FormControl fullWidth>
							<InputLabel id="select-product">Product</InputLabel>
							<Select
								id="select-product"
								label="Product"
								value={selectedAPI}
								onChange={(newProduct) =>
									setSelectedAPI(newProduct.target.value as AvailableApi | "All")
								}
							>
								{["All", ...Object.values(ALL_APIS)].map((value) => (
									<MenuItem key={value} value={value}>
										{value}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label="Search by api, cruncho_slug, api_category_id and destination"
							onChange={(event) => {
								// keeping track of the current search query
								setSearchQuery(event.target.value);
							}}
							value={searchQuery}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								endAdornment: searchQuery && (
									<InputAdornment position="end">
										<IconButton
											onClick={() => setSearchQuery("")}
											size="large"
											aria-label="clear"
										>
											<ClearIcon />
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
			</CardContent>
			<CardContent>
				<Typography variant="body1" color="textSecondary">
					Category Mappings
				</Typography>
				{filteredCategoryMappings.length > 0 ? (
					<FixedSizeList
						height={500}
						itemCount={filteredCategoryMappings.length}
						itemData={filteredCategoryMappings}
						itemSize={70}
						width={"100%"}
					>
						{({ data, index, style }) => (
							<div style={style}>
								<CategoryMappingListItem
									key={data[index]._id}
									mapping={data[index]}
									onDeleteSelected={(_mapping) =>
										onCategoryMappingDeleteSelected(_mapping)
									}
									onEditSelected={(_mapping) => onCategoryMappingEditSelected(_mapping)}
								/>
							</div>
						)}
					</FixedSizeList>
				) : (
					<Typography variant="body2" color="textSecondary">
						No category mapping available
					</Typography>
				)}
			</CardContent>
		</Card>
	);
}
