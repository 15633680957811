import {
	CategoryMapping,
	CategoryMappingCreateInput,
	categoryMappingSchemaCreateInput,
} from "@cruncho/cruncho-shared-types";
import Dialog from "@mui/material/Dialog";
import axios, { AxiosError } from "axios";
import { Formik, prepareDataForValidation } from "formik";
import { useSnackbar } from "notistack";

import { api } from "../../services/api";
import { CreateOrEditCategoryMappingForm } from "./CreateOrEditCategoryMappingForm";

type CreateCategoryMappingDialogProps = {
	/**
	 * Function called on close (without saving document)
	 */
	onClose: () => void;
	/**
	 * Function called when clicking on "save"
	 */
	onSave: (_mapping: CategoryMapping) => void;
	/**
	 * Control display state of dialog
	 */
	open: boolean;
};

/**
 * A dialog to create or edit an existing category mapping.
 */
export function CreateCategoryMappingDialog({
	onClose,
	onSave,
	open,
}: CreateCategoryMappingDialogProps) {
	const { enqueueSnackbar } = useSnackbar();

	const initialValues: CategoryMappingCreateInput = {
		api: "google",
		apiCategoryId: "",
		crunchoSlug: "to_edit",
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<Formik
				enableReinitialize
				validate={(values) => {
					try {
						const preparedData = prepareDataForValidation(values);
						categoryMappingSchemaCreateInput.parse(preparedData);
					} catch (error: any) {
						console.error(error);
						return error.formErrors;
					}
				}}
				initialValues={initialValues}
				onSubmit={async (values) => {
					const preparedData = prepareDataForValidation(values);
					try {
						const parsedCategoryMapping =
							categoryMappingSchemaCreateInput.parse(preparedData);
						if (parsedCategoryMapping.destinationSlug === "All destinations") {
							delete parsedCategoryMapping.destinationSlug;
						}
						const updated = await api.category.mappings.create(parsedCategoryMapping);
						enqueueSnackbar(`${updated.apiCategoryId} updated!`, {
							variant: "success",
						});
						onSave(updated);
					} catch (error: any) {
						console.error(error);
						// Mongoose error code for duplicate
						if (
							axios.isAxiosError(error) &&
							(error as AxiosError<{ code: string }>).response?.data?.code === "11000"
						) {
							enqueueSnackbar("A category with the same id/name already exists", {
								variant: "warning",
								persist: true,
							});
						} else if (
							axios.isAxiosError(error) &&
							(error as AxiosError<{ message: string }>).response?.data?.message
						) {
							enqueueSnackbar(
								(error as AxiosError<{ message: string }>).response?.data?.message,
								{
									variant: "error",
									persist: true,
								},
							);
						} else {
							enqueueSnackbar(JSON.stringify(error, null, 2), {
								variant: "error",
								persist: true,
							});
						}
					}
				}}
			>
				{({ dirty, isSubmitting }) => (
					<CreateOrEditCategoryMappingForm
						dirty={dirty}
						isSubmitting={isSubmitting}
						onClose={onClose}
					/>
				)}
			</Formik>
		</Dialog>
	);
}
