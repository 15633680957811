import { ChildCategory } from "@cruncho/cruncho-shared-types";
import { APIBase } from "../APIBase";

/**
 * A class handling the interactions with l3-related endpoints in the api
 */
export class APIL3 extends APIBase {
	async get(id: string) {
		const response = await this.api.get<ChildCategory>(`/categories/l3/${id}`);
		return response.data;
	}

	async getAll() {
		const response = await this.api.get<Array<ChildCategory>>("/categories/l3");
		return response.data;
	}

	async update(id: string, category: ChildCategory) {
		const response = await this.api.put<ChildCategory>(
			`/categories/l3/${id}`,
			category,
		);
		return response.data;
	}

	async create(category: ChildCategory) {
		const response = await this.api.post<ChildCategory>(
			"/categories/l3",
			category,
		);
		return response.data;
	}

	async delete(slug: string) {
		await this.api.delete(`/categories/l3/${slug}`);
	}
}
