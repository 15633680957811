import { ChildCategory, Category } from "@cruncho/cruncho-shared-types";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { useCallback, useEffect, useState } from "react";
import { api } from "../../services/api";
import { CategoryList } from "./CategoryList";
import { CreateCategoryDialog } from "./CreateCategoryDialog";
import { EditCategoryDialog } from "./EditCategoryDialog";
import { OrderL2CategoriesDialog } from "./OrderL2CategoriesDialog";
import { CategoryType } from "./types";

export function CategoriesView() {
	const [l1Categories, setL1Categories] = useState<Array<Category>>([]);
	const [l2Categories, setL2Categories] = useState<Array<ChildCategory>>([]);
	const [l3Categories, setL3Categories] = useState<Array<ChildCategory>>([]);

	/**
	 * Keeping track of the selected category in each list
	 */
	const [selectedL1Category, setSelectedL1Category] = useState<Category>();
	const [selectedL2Category, setSelectedL2Category] = useState<ChildCategory>();
	const [selectedL3Category, setSelectedL3Category] = useState<ChildCategory>();

	/**
	 * Content of the lists. A subselection is made to only display the categories under the parrent one
	 *
	 * @example
	 * Selecting "hotel" l1 will trigger a subselection of l2s that are under the "hotel" l1
	 */
	const [l2CategorySubSelection, setL2CategorySubSelection] =
		useState<Array<ChildCategory>>(l2Categories);
	const [l3CategorySubSelection, setL3CategorySubSelection] =
		useState<Array<ChildCategory>>(l3Categories);

	const fetchAllCategories = useCallback(async () => {
		const [fetchedL1Categories, fetchedL2Categories, fetchedL3Categories] =
			await Promise.all([
				api.category.l1.getAll(),
				api.category.l2.getAll(),
				api.category.l3.getAll(),
			]);

		setL1Categories(fetchedL1Categories);
		setL2Categories(fetchedL2Categories);
		setL3Categories(fetchedL3Categories);
	}, []);
	/**
	 * Fetching all at startup
	 * */
	useEffect(() => {
		fetchAllCategories();
	}, [fetchAllCategories]);

	// used to open both the creation and edition modal
	const [openModal, setOpenModal] = useState(false);
	const [openOrderL2CategoriesDialog, setOpenOrderL2CategoriesDialog] =
		useState(false);

	/**
	 * An object containing information about the current selected category
	 */
	const [editingCategory, setEditingCategory] = useState<{
		/**
		 * data related to the selected category.
		 * If undefined, it means that we don't want to edit a category, but to create a new one
		 */
		category?: Category | ChildCategory;
		/**
		 * The type of the selected category
		 */
		type: CategoryType;
		/**
		 * Used for the category creation
		 *
		 * If a user clicks on a category (add button), it means that we want to create a new category UNDER this category.
		 * Thus, defaultParentSlug will be the slug of the clicked category.
		 */
		defaultParentSlug?: string;
	}>();

	/**
	 * Triggered when selecting a l1 category (used to set subselections for l2s and l3s)
	 * @param category - the clicked l1 category
	 */
	const handleL1CategorySelection = (category?: Category) => {
		// selecting the given l1
		setSelectedL1Category(category);
	};

	// on L1 selection
	useEffect(() => {
		// clicked on a l1 category
		if (selectedL1Category) {
			// showing all available L2s
			const newL2CategorySubSelection = l2Categories.filter(
				(l2Category) => l2Category.parentSlug === selectedL1Category.slug,
			);
			setL2CategorySubSelection(newL2CategorySubSelection);
		}
		// Clicked on the ALL row in the l1 list
		else {
			setL2CategorySubSelection(l2Categories);
		}
		setSelectedL2Category(undefined);
	}, [l2Categories, selectedL1Category]);

	// on L2 selection
	useEffect(() => {
		if (selectedL2Category) {
			// showing all l3 under the available l2
			setL3CategorySubSelection(
				l3Categories.filter(
					(l3Category) => l3Category.parentSlug === selectedL2Category.slug,
				),
			);
		} else {
			// showing all available L2s
			setL3CategorySubSelection(l3Categories);
		}
		setSelectedL3Category(undefined);
	}, [l2CategorySubSelection, l3Categories, selectedL2Category]);

	/**
	 * Triggered when selecting a l2 category (used to set subselections for l3s)
	 * @param category - the clicked l2 category
	 */
	const handleL2CategorySelection = (category?: ChildCategory) => {
		// selecting the given l1
		setSelectedL2Category(category);
	};

	/**
	 * Triggered when selecting a l3 category
	 * @param category - the clicked l3 category
	 */
	const handleL3CategorySelection = (category?: ChildCategory) => {
		setSelectedL3Category(category);
	};

	/**
	 * triggered when a category is selected for edition
	 */
	const handleEditCategory = (
		type: CategoryType,
		category?: Category | ChildCategory,
	) => {
		setEditingCategory({ category, type });
		setOpenModal(true);
	};

	/**
	 * triggered when a category is selected to create child category (L1 => L2, L2 => L3)
	 * @param parentType - The type (l1, l2, l3) of the parent category
	 * @param parentSlug - The slug of the parent category
	 */
	const handleAddCategory = (parentType: CategoryType, parentSlug: string) => {
		// Should not be possible
		if (parentType === "L3") {
			throw new Error(
				"Error, trying to add a subcategory to l3. This should not be possible",
			);
		}

		/**
		 * Creating L2 if L1 selected, L3 if L2 selected
		 */
		const childType = parentType === "L1" ? "L2" : "L3";

		setEditingCategory({
			category: undefined,
			type: childType,
			defaultParentSlug: parentSlug,
		});
		setOpenModal(true);
	};

	/**
	 * triggered when a category is selected to be deleted
	 */
	const handleDeleteCategory = async (type: CategoryType, slug: string) => {
		if (!slug) {
			throw new Error("Error, trying to delete a category without slug");
		}

		const confirm = window.confirm(
			"Are you sure you want to delete this category ?",
		);

		if (!confirm) {
			return;
		}

		if (type === "L1") {
			throw new Error("Not allowed to delete L1 categories");
		} else if (type === "L2") {
			throw new Error("Not allowed to delete L2 categories");
		} else if (type === "L3") {
			await api.category.l3.delete(slug);
		} else {
			throw new Error("Error, unknown category type");
		}

		await fetchAllCategories();
	};

	/**
	 * triggered after a category was saved in a modal
	 */
	const handleSaveCategory = async () => {
		// better job could be done here by checking which category has changed
		// and avoid requesting everything back again

		await fetchAllCategories();
	};

	/**
	 * triggered after a category was created in a modal
	 */
	const handleCreateCategory = async () => {
		// better job could be done here by checking the created category and append it to the correct list,
		// and avoid requesting everything back again

		await fetchAllCategories();
	};

	return (
		<div className="p-4">
			<Button
				variant="contained"
				color="primary"
				aria-label="order l2 categories"
				onClick={() => {
					setOpenOrderL2CategoriesDialog(true);
				}}
				className="mb-4"
			>
				Order L2 categories
			</Button>

			<Grid container spacing={2}>
				<Grid item xs={4}>
					<CategoryList
						categories={l1Categories}
						displayRowAll
						onCategoryEditSelected={handleEditCategory}
						onSelectCategory={handleL1CategorySelection}
						selectedCategory={selectedL1Category}
						type="L1"
						onCategoryAddSelected={handleAddCategory}
					/>
				</Grid>
				<Grid item xs={4}>
					<CategoryList
						categories={l2CategorySubSelection}
						displayRowAll
						onCategoryEditSelected={handleEditCategory}
						onSelectCategory={handleL2CategorySelection}
						selectedCategory={selectedL2Category}
						type="L2"
						onCategoryAddSelected={handleAddCategory}
					/>
				</Grid>
				<Grid item xs={4}>
					<CategoryList
						categories={l3CategorySubSelection}
						displayRowAll={false}
						onCategoryEditSelected={handleEditCategory}
						onSelectCategory={handleL3CategorySelection}
						onCategoryDeleteSelected={handleDeleteCategory}
						selectedCategory={selectedL3Category}
						type="L3"
					/>
				</Grid>
			</Grid>

			{/* if the current category is undefined, it means we are in creation mode else, it's edit mode */}
			{editingCategory?.category ? (
				<EditCategoryDialog
					open={openModal}
					category={editingCategory?.category}
					categoryType={editingCategory?.type ?? "L1"}
					onClose={() => setOpenModal(false)}
					onSave={async () => {
						await handleSaveCategory();
						setOpenModal(false);
						setEditingCategory(undefined);
					}}
					parentSlugs={
						editingCategory?.type === "L2"
							? l1Categories.map((category) => category.slug)
							: editingCategory?.type === "L3"
							? l2Categories.map((category) => category.slug)
							: undefined
					}
				/>
			) : (
				<CreateCategoryDialog
					open={openModal}
					categoryType={editingCategory?.type ?? "L1"}
					onClose={() => setOpenModal(false)}
					onSave={async () => {
						await handleCreateCategory();
						setOpenModal(false);
						setEditingCategory(undefined);
					}}
					parentSlugs={
						editingCategory?.type === "L2"
							? l1Categories.map((category) => category.slug)
							: editingCategory?.type === "L3"
							? l2Categories.map((category) => category.slug)
							: undefined
					}
					defaultParentSlug={editingCategory?.defaultParentSlug}
				/>
			)}

			<OrderL2CategoriesDialog
				open={openOrderL2CategoriesDialog}
				onClose={(shouldRefresh?: boolean) => {
					setOpenOrderL2CategoriesDialog(false);
					if (shouldRefresh) {
						api.category.l2.getAll().then(setL2Categories);
					}
				}}
				categories={l2Categories}
			/>
		</div>
	);
}
