import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

type BugReportRejectDialogProps = {
	/**
	 * Close the dialog
	 */
	handleClose: () => void;
	/**
	 * Action to execute when confirming rejection
	 */
	onConfirm: () => Promise<void>;
	/**
	 * Manage dialog display state
	 */
	open: boolean;
};

export function BugReportRejectDialog({
	handleClose,
	onConfirm,
	open,
}: BugReportRejectDialogProps) {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{"Reject this bug report?"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Rejecting a bug report means that no one will work on it: you should do so
					if the bug report is not usable, it does not look like a bug...
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button
					onClick={async () => {
						await onConfirm();
					}}
					autoFocus
				>
					Reject
				</Button>
			</DialogActions>
		</Dialog>
	);
}
