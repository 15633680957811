import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import {
	createTheme,
	StyledEngineProvider,
	ThemeProvider,
} from "@mui/material/styles";

import { SnackbarProvider } from "notistack";
import { useRef } from "react";
import { CustomRouter } from "./CustomRouter";
import MainNav from "./MainNav";

import "./index.css";

const theme = createTheme({
	palette: {
		primary: {
			main: "#3f51b5",
		},
		secondary: {
			main: "#f50057",
		},
	},
});

export function App() {
	// A ref to notistack to add actions (hiding snackbar button)
	const notistackRef = useRef<SnackbarProvider | null>(null);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<SnackbarProvider
					ref={(ref) => {
						notistackRef.current = ref;
					}}
					maxSnack={4}
					autoHideDuration={3_000}
					action={(key) => (
						<Button onClick={() => notistackRef.current?.closeSnackbar(key)}>
							Dismiss
						</Button>
					)}
				>
					<CssBaseline />
					<MainNav />
					<main>
						<CustomRouter />
					</main>
				</SnackbarProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}
