import { SoulcircusProduct } from "@cruncho/cruncho-shared-types";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";

type ProductListItemProps = {
	product?: SoulcircusProduct;

	/**
	 * A callback triggered when clicking the delete button
	 */
	onDeleteSelected: (product?: SoulcircusProduct) => void;

	/**
	 * A callback triggered when clicking the edit button
	 */
	onEditSelected: (product?: SoulcircusProduct) => void;
};

/**
 * A component to display one product in a list row
 */
export function ProductListItem({
	product,
	onDeleteSelected,
	onEditSelected,
}: ProductListItemProps) {
	const [openDeleteWarningDialog, setOpenDeleteWarningDialog] = useState(false);

	return product ? (
		<ListItemButton>
			<ListItemText
				primary={product.name}
				secondary={`${product.description} - ${product.price} sek/mo - ${product.recurring} - ${product.type} ${product.bookingLimit ? `with booking limit of ${product.bookingLimit}` : ""}`}
			/>

			<IconButton
				title="Edit this product"
				aria-label="edit this product"
				edge="end"
				onClick={() => {
					onEditSelected(product);
				}}
				size="large"
			>
				<EditIcon />
			</IconButton>
			<IconButton
				title="Delete this category mapping"
				aria-label="delete this category mapping"
				edge="end"
				onClick={() => setOpenDeleteWarningDialog(true)}
				size="large"
				sx={{ ":hover": { color: "red" } }}
			>
				<DeleteIcon />
			</IconButton>
			<Dialog
				open={openDeleteWarningDialog}
				onClose={() => setOpenDeleteWarningDialog(false)}
			>
				<DialogTitle id="alert-dialog-title">Delete this Product?</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						If you delete this product, it will be erased from the database. There is
						no way to retrieve it.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => setOpenDeleteWarningDialog(false)}
						color="error"
						variant="outlined"
						aria-label="cancel"
					>
						Cancel
					</Button>
					<Button
						onClick={() => onDeleteSelected(product)}
						autoFocus
						color="success"
						variant="outlined"
						aria-label="delete"
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</ListItemButton>
	) : (
		<ListItemButton>
			<ListItemText primary="ALL" />
		</ListItemButton>
	);
}
