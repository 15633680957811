import {
	BooleanWithDescriptionField,
	StringArrayWithDescriptionField,
	StringWithDescriptionField,
	ScrapersBooleanWithDescriptionField,
} from "@cruncho/components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export function ScrapersSection() {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h6">Scrapers</Typography>
			</Grid>
			<Grid item container spacing={2} xl={4} lg={6} xs={12} className="h-fit">
				<Grid item xs={12}>
					<BooleanWithDescriptionField
						name="Enable Ticketmaster"
						description="When activated, Ticketmaster fetching is enabled (not implemented in the scrapers yet)."
						databaseName="features.scrapers.ticketmaster.enabled"
					/>
				</Grid>
				<Grid item xs={12}>
					<StringWithDescriptionField
						name="Ticketmaster country code"
						description="Country code."
						databaseName="features.scrapers.ticketmaster.countryCode"
					/>
				</Grid>
				<Grid item xs={12}>
					<StringWithDescriptionField
						name="Ticketmaster share ID"
						description="Ticketmaster share ID."
						databaseName="features.scrapers.ticketmaster.shareId"
					/>
				</Grid>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<StringArrayWithDescriptionField
					freeSolo
					name="Ticketmaster cities"
					description="List of the cities for which to search events (in their local language for better results)."
					databaseName="features.scrapers.ticketmaster.city"
				/>
			</Grid>
			<Grid item container spacing={2} xl={4} lg={6} xs={12} className="h-fit">
				<Grid item xs={12}>
					<StringWithDescriptionField
						name="Ticketmaster locale"
						description='Locale of the country. VITAL for good fetching results. Put something like "en-us,*".'
						databaseName="features.scrapers.ticketmaster.locale"
					/>
				</Grid>
				<Grid item xs={12}>
					<StringWithDescriptionField
						name="Ticketmaster impact link"
						description="Ticketmaster impact link."
						databaseName="features.scrapers.ticketmaster.impactLink"
					/>
				</Grid>
			</Grid>
			<Grid item container spacing={2} xl={4} lg={6} xs={12} className="h-fit">
				<Grid item xs={12}>
					<BooleanWithDescriptionField
						name="Enable Reservix"
						description="When activated, Reservix fetching is enabled (not implemented in the scrapers yet)."
						databaseName="features.scrapers.reservix.enabled"
					/>
				</Grid>
				<Grid item xs={12}>
					<StringArrayWithDescriptionField
						freeSolo
						name="Reservix cities"
						description="List of the cities for which to search events (PLEASE check if the city name is known by Reservix API)."
						databaseName="features.scrapers.reservix.city"
					/>
				</Grid>
			</Grid>
			<Grid item container spacing={2} xl={4} lg={6} xs={12} className="h-fit">
				<Grid item xs={12}>
					<BooleanWithDescriptionField
						name="Enable Eventim"
						description="When activated, Eventim fetching is enabled (not implemented in the scrapers yet)."
						databaseName="features.scrapers.eventim.enabled"
					/>
				</Grid>
				<Grid item xs={12}>
					<StringArrayWithDescriptionField
						freeSolo
						name="Eventim cities"
						description="List of the cities for which to search events (NAMES IN UPPERCASE)."
						databaseName="features.scrapers.eventim.city"
					/>
				</Grid>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Enable Ticketco"
					description="When activated, Ticketco fetching is enabled (not implemented in the scrapers yet)."
					databaseName="features.scrapers.ticketco.enabled"
				/>
			</Grid>
			<Grid item container spacing={2} xl={4} lg={6} xs={12} className="h-fit">
				<Grid item xs={12}>
					<StringArrayWithDescriptionField
						freeSolo
						name="Facebook Events cities"
						description="List of the cities for which to search events; follow the format <id>:<name> (ex: '106505586052951:Stockholm')."
						databaseName="features.scrapers.facebookEvents.city"
					/>
				</Grid>
				<Grid item xs={12}>
					<StringArrayWithDescriptionField
						freeSolo
						name="Facebook Events search texts"
						description="List of the text inputs to search events for. By default, put a blank space (' '); otherwise, make sure there is a category mapping for each text input."
						databaseName="features.scrapers.facebookEvents.searchTexts"
					/>
				</Grid>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<ScrapersBooleanWithDescriptionField
					name="Autoapprove scrapers events"
					description="When activated, the events coming from this API will be automatically approved"
					databaseName="features.scrapers"
				/>
			</Grid>
		</Grid>
	);
}
