import {
	ClipboardCopyButton,
	SelectField,
	SwitchField,
	TextField,
} from "@cruncho/components";
import {
	ChildCategory,
	Category,
	childCategorySchema,
	categorySchema,
} from "@cruncho/cruncho-shared-types";

import ContentPasteIcon from "@mui/icons-material/ClearAll";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MuiTextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import axios, { AxiosError } from "axios";
import { Form, Formik, prepareDataForValidation } from "formik";
import { useSnackbar } from "notistack";
import { sanitize } from "utils";
import { z } from "zod";
import { api } from "../../services/api";
import { CategoryType } from "./types";

type CreateCategoryDialogProps<C extends Category | ChildCategory> = {
	categoryType: CategoryType;
	/**
	 * The parent slug. This allows to show a default parent slug in the select box
	 */
	defaultParentSlug?: string;
	onClose: () => void;
	onSave: (category: C, type: CategoryType) => void;
	open: boolean;
	/**
	 * A list of parent slugs displayed for selection
	 */
	parentSlugs?: Array<string>;
};

/**
 * Default configuration to prefill a category.
 *
 * Add a displayName just for the form, so that it get sanitized to a slug.
 * Only the slug is kept in the API
 */
const defaultCategoryBase: Omit<Category, "slug"> & { displayName: string } = {
	displayName: "",
	default: false,
	hideOnEM: false,
};

/**
 * A dialog to create a new category
 */
export function CreateCategoryDialog<C extends Category | ChildCategory>({
	categoryType,
	defaultParentSlug,
	onClose,
	onSave,
	open,
	parentSlugs,
}: CreateCategoryDialogProps<C>) {
	const { enqueueSnackbar } = useSnackbar();

	// for creation mode, using the default values that correspond to the wanted category type
	// @ts-ignore typescript complains that we may change the type at this step, but we make sure the same as the incomming category

	// We add a displayName just for the form, so that it get sanitized to a slug.
	// Only the slug is kept in the API
	const initialValues: ChildCategory & { displayName: string } = {
		...defaultCategoryBase,
		parentSlug: defaultParentSlug ?? "",
	};

	// we use a schema compatible with the wanted categoryType
	const partialSchema = (
		categoryType === "L1" ? categorySchema : childCategorySchema
	)
		.omit({ slug: true })
		// We add a displayName just for the form, so that it get sanitized to a slug.
		// Only the slug is kept in the API
		.merge(z.object({ displayName: z.string() }));

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validate={(values) => {
					try {
						const preparedData = prepareDataForValidation(values);
						partialSchema.parse(preparedData);
					} catch (error: any) {
						return error.formikError;
					}
				}}
				onSubmit={async (values) => {
					const preparedData = prepareDataForValidation(values);

					try {
						const parsedCategory = partialSchema.parse(preparedData);

						// In creation mode, we simplify the process by automatically setting fields related to the displayName
						const sanitizedName = sanitize(parsedCategory.displayName);

						const automaticallyFilledCategory = {
							...parsedCategory,
							slug: sanitizedName,
						};

						// we don't offer for now the possibility to create a new l1
						// so we parse with the childCategorySchema
						const finalCategory = childCategorySchema.parse(
							automaticallyFilledCategory,
						);

						// selecting the correspoding api endpoint
						const endpoint =
							categoryType === "L2" ? api.category.l2 : api.category.l3;

						try {
							const createdCategory = await endpoint.create(finalCategory);

							enqueueSnackbar(`${finalCategory.slug} created!`, {
								variant: "success",
							});

							// @ts-ignore
							onSave(createdCategory, categoryType);
						} catch (error: any) {
							console.error(error);
							if (axios.isAxiosError(error) && error.response?.status === 400) {
								enqueueSnackbar("A category with the same id/name already exists", {
									variant: "warning",
									persist: true,
								});
							} else if (
								axios.isAxiosError(error) &&
								(error as AxiosError<{ message: string }>).response?.data?.message
							) {
								enqueueSnackbar(
									(error as AxiosError<{ message: string }>).response?.data?.message,
									{
										variant: "error",
										persist: true,
									},
								);
							} else {
								enqueueSnackbar(JSON.stringify(error, null, 2), {
									variant: "error",
									persist: true,
								});
							}
						}
					} catch (error) {
						console.error(error);
						enqueueSnackbar(JSON.stringify(error, null, 2), {
							variant: "error",
							persist: true,
						});
					}
				}}
			>
				{({ values, dirty, isSubmitting, setValues }) => (
					<Form>
						<DialogTitle>
							<Grid container>
								<Grid item style={{ flex: 1 }}>
									{"parentSlug" in values
										? `Add a new ${categoryType} category under ${values.parentSlug}`
										: `Add a new ${categoryType} category`}
								</Grid>

								<Grid item>
									<Button
										size="medium"
										title="Paste category from the clipboard"
										color="inherit"
										onClick={async () => {
											try {
												alert("not implemented");

												const pastedObject = JSON.parse(
													await navigator.clipboard.readText(),
												);
												const checkedCategory = partialSchema.parse(pastedObject);

												// @ts-ignore typescript is still ensure about the generic hack.
												setValues(checkedCategory);
												enqueueSnackbar("Successfully pasted category from Clipboard", {
													variant: "success",
												});
											} catch (error) {
												console.error(error);
												enqueueSnackbar(JSON.stringify(error, null, 2), {
													variant: "error",
													persist: true,
												});
											}

											navigator.clipboard.writeText(JSON.stringify(values, null, 4));
											enqueueSnackbar("Category Copied to Clipboard", {
												variant: "info",
											});
										}}
									>
										<ContentPasteIcon />
									</Button>

									<ClipboardCopyButton
										data={values}
										title="Copy category"
										onCopyDone={() => {
											enqueueSnackbar("Category Copied to Clipboard", {
												variant: "info",
											});
										}}
									/>
								</Grid>
							</Grid>
						</DialogTitle>
						<DialogContent>
							<DialogContentText>You can create here a new category</DialogContentText>

							<Grid container spacing={2}>
								{categoryType !== "L1" && parentSlugs && (
									<Grid item xs={6}>
										<Typography gutterBottom variant="h6">
											Parent category
										</Typography>
										<Grid container>
											<Grid item xs={12}>
												<SelectField
													size="small"
													fullWidth
													label="Parent Slug"
													name="parentSlug"
													items={
														parentSlugs.map((option) => ({
															value: option,
															label: option,
														})) ?? []
													}
													variant="outlined"
												/>
											</Grid>
										</Grid>
									</Grid>
								)}

								<Grid item xs={12}>
									<Typography gutterBottom variant="h6">
										General information
									</Typography>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<TextField
												fullWidth
												name="displayName"
												label="Display Name"
												variant="standard"
											/>
										</Grid>

										<Grid item xs={6}>
											<MuiTextField
												fullWidth
												variant="standard"
												disabled
												label="Slugs and ids will automatically have the value "
												value={sanitize(values.displayName)}
											/>
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12}>
									<Typography gutterBottom variant="h6">
										Customization
									</Typography>
									<Grid container spacing={2}>
										<Grid item xs={12} md={6}>
											<SwitchField label="Is a Default category?" name="default" />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button onClick={onClose} color="primary">
								Cancel
							</Button>
							{dirty && (
								<Button
									type="submit"
									color="secondary"
									variant="outlined"
									disabled={isSubmitting}
								>
									Create
								</Button>
							)}
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
}
