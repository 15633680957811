import { AxiosInstance } from "axios";

/**
 * An instance of axios that will be used to access the backend.
 *
 */
export class APIBase {
	protected api: AxiosInstance;

	constructor(api: AxiosInstance) {
		this.api = api;
	}
}
