import { BooleanWithDescriptionField } from "@cruncho/components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

/**
 * Section of fields related to features filters and hooked to formik
 */
export function FiltersSection() {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h6">Filters</Typography>
			</Grid>

			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Grey out empty filter pills"
					description="When activated, the pills of the level 3 categories that do not contain any recommendations are greyed out instead of being hidden."
					databaseName="features.greyOutEmptyPills"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Event time filter"
					description="When activated, a time filter is added to the date filter in the calendar option."
					databaseName="features.showTimesOnDatePicker"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Area filter"
					description="When activated, an area filter is added to the guide in the header."
					databaseName="features.showAreaFilter"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Area filter only for admin"
					description='When activated, the area filter is only shown to admins. The filter just before this one, "Area filter", must be activated.'
					databaseName="features.showAreaFilterOnlyToAdmins"
				/>
			</Grid>
			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Show 1 date picker is the map"
					description="When activated, only 1 date picker will be displayed on the map (the endDate is equal to the end of the startDate)"
					databaseName="features.showOnlyOneDatePickerInMap"
				/>
			</Grid>
		</Grid>
	);
}
