import { SoulcircusCategory } from "@cruncho/cruncho-shared-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { api } from "services/api";

type DeleteSoulcircusCategoryDialogProps = {
	category: SoulcircusCategory;
	onClose: () => void;
	onDelete: () => void;
	open: boolean;
	selectedType: string;
};

export function DeleteSoulcircusCategoryDialog({
	category,
	onClose,
	onDelete,
	open,
	selectedType,
}: DeleteSoulcircusCategoryDialogProps) {
	const { enqueueSnackbar } = useSnackbar();

	const onDeleteClick = async () => {
		try {
			const updateResult = await api.soulcircusCategory.update(category.slug, {
				slug: category.slug,
				type: category.type.filter((type) => !(type === selectedType)),
			});
			if (updateResult)
				enqueueSnackbar(`${category.slug} deleted in the ${selectedType}!`, {
					variant: "success",
				});
			onDelete();
		} catch (error: any) {
			enqueueSnackbar(
				(error as AxiosError<{ message: string }>).response?.data?.message,
				{
					variant: "error",
					persist: true,
				},
			);
		}
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle>{`Delete this category from the "${selectedType}" type`}</DialogTitle>
			<DialogContent>
				{`Do you really want to remove the category from the "${selectedType}" type ?`}
			</DialogContent>
			<DialogActions>
				<Button
					type="submit"
					color="secondary"
					variant="outlined"
					onClick={onDeleteClick}
				>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
}
