import {
	AvailableBugStatus,
	BugReport,
	BUG_REPORT_STATUS,
} from "@cruncho/cruncho-shared-types";

import Grid from "@mui/material/Grid";
import axios, { AxiosError } from "axios";
import { useSnackbar } from "notistack";

import { useEffect, useState } from "react";
import { api } from "../../services/api";
import { BugReportRejectDialog } from "./BugReportRejectDialog";
import { BugReportsDetails } from "./BugReportsDetails";
import { BugReportsList } from "./BugReportsList";

/**
 * A page displaying the list of bug reports and allowing to focus on one, close it or reject it
 */
export function BugReportsView() {
	const { enqueueSnackbar } = useSnackbar();

	const [bugReportsList, setBugReportsList] = useState<Array<BugReport>>([]);
	const [selectedBugReport, setSelectedBugReport] = useState<BugReport | null>(
		null,
	);

	const [selectedStatus, setSelectedStatus] = useState<AvailableBugStatus>(
		BUG_REPORT_STATUS.OPEN,
	);

	/**
	 * Fetch open bug reports on first load
	 */
	useEffect(() => {
		const fetchAllBugReports = async (status?: AvailableBugStatus) => {
			try {
				const fetchBugReports = await api.bugReports.getAll(status);
				setBugReportsList(fetchBugReports);
				setSelectedBugReport(null);
			} catch (error) {
				enqueueSnackbar(JSON.stringify(error, null, 2), {
					variant: "error",
					persist: true,
				});
			}
		};
		fetchAllBugReports(selectedStatus);
	}, [enqueueSnackbar, selectedStatus]);

	/**
	 * Used to open the modals
	 */
	const [openRejectConfirmationModal, setOpenRejectConfirmationModal] =
		useState(false);

	/**
	 * Triggered after a bug report has been rejected
	 */
	const handleRejectBugReport = async (id: string) => {
		try {
			await api.bugReports.reject(id);
			setBugReportsList(
				bugReportsList.filter((bugReport) => bugReport._id !== id),
			);
			enqueueSnackbar(`Bug Report ${id} successfully rejected`, {
				variant: "success",
			});
			setOpenRejectConfirmationModal(false);
			setSelectedBugReport(null);
		} catch (error) {
			if (
				axios.isAxiosError(error) &&
				(error as AxiosError<{ message: string }>).response?.data?.message
			) {
				enqueueSnackbar(
					(error as AxiosError<{ message: string }>).response?.data?.message,
					{
						variant: "error",
						persist: true,
					},
				);
			} else {
				enqueueSnackbar(JSON.stringify(error, null, 2), {
					variant: "error",
					persist: true,
				});
			}
		}
	};

	/**
	 * Triggered after a bug report has been closed
	 */
	const handleCloseBugReport = async (id: string) => {
		try {
			await api.bugReports.close(id);
			setBugReportsList(
				bugReportsList.filter((bugReport) => bugReport._id !== id),
			);
			enqueueSnackbar(`Bug Report ${id} successfully closed`, {
				variant: "success",
			});
			setSelectedBugReport(null);
		} catch (error) {
			if (
				axios.isAxiosError(error) &&
				(error as AxiosError<{ message: string }>).response?.data?.message
			) {
				enqueueSnackbar(
					(error as AxiosError<{ message: string }>).response?.data?.message,
					{
						variant: "error",
						persist: true,
					},
				);
			} else {
				enqueueSnackbar(JSON.stringify(error, null, 2), {
					variant: "error",
					persist: true,
				});
			}
		}
	};

	return (
		<div className="p-4">
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<BugReportsList
						bugReportsList={bugReportsList}
						onBugReportSelected={setSelectedBugReport}
						onStatusSelected={setSelectedStatus}
						selectedBugReport={selectedBugReport}
						selectedStatus={selectedStatus}
					/>
				</Grid>
				{selectedBugReport && (
					<Grid
						item
						xs={8}
						sx={{ maxHeight: "calc(100vh - 64px - 16px)", overflow: "auto" }}
					>
						<BugReportsDetails
							bugReport={selectedBugReport}
							onBugReportClose={async () => {
								try {
									await handleCloseBugReport(selectedBugReport._id);
								} catch (error) {
									console.error(error);
									enqueueSnackbar(JSON.stringify(error, null, 2), {
										variant: "error",
										persist: true,
									});
								}
							}}
							onBugReportReject={() => setOpenRejectConfirmationModal(true)}
						/>
					</Grid>
				)}
			</Grid>
			{selectedBugReport && (
				<BugReportRejectDialog
					handleClose={() => setOpenRejectConfirmationModal(false)}
					onConfirm={async () => handleRejectBugReport(selectedBugReport._id)}
					open={openRejectConfirmationModal}
				/>
			)}
		</div>
	);
}
