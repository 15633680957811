import axios, { AxiosInstance } from "axios";
import { APIAuth } from "./auth";
import { APIBugReports } from "./bugReports";
import { APICategory } from "./category/category";
import { APIDestination } from "./destination";
import { APIFormat } from "./format";
import { APIPractitioner } from "./soulcircus/practitioner";
import { APISoulcircusCategory } from "./soulcircus/soulcircusCategory";
import { APIProduct } from "./soulcircus/soulcircusProduct";

/**
 * Available environments
 */
export const ENVIRONEMENTS = [
	"localhost",
	"local",
	"staging",
	"production",
] as const;
export type Environment = (typeof ENVIRONEMENTS)[number];

export const currentEnvironment: Environment =
	(process.env.REACT_APP_ENV as Environment) ?? "localhost";

/**
 * mapping an environment to the corresponding url
 */
export const environmentToUrl: Record<Environment, string> = {
	localhost: "http://localhost:3000",
	local: `http://api-ts.cruncho.${process.env.REACT_APP_LOCAL_DOMAIN}:3000`,
	staging: "https://api-ts.cruncho.in",
	production: "https://api-ts.cruncho.co",
};

/**
 * mapping an environment to the corresponding url
 */
export const environmentToWebroot = (): string => {
	if (process.env.NODE_ENV === "development") {
		return "http://localhost:3014";
	}
	const webroot = {
		localhost: "http://localhost:3014",
		local: `http://admin.cruncho.${process.env.REACT_APP_LOCAL_DOMAIN}:3014`,
		staging: "https://admin.cruncho.in",
		production: "https://admin.cruncho.co",
	};
	return webroot[currentEnvironment];
};

export const instance: AxiosInstance = axios.create({
	baseURL: environmentToUrl[currentEnvironment],
});

/**
 * The main api object. Use it to interact with the api.
 *
 * @example
 * await api.destination.getAll()
 */
export const api = {
	bugReports: new APIBugReports(instance),
	destination: new APIDestination(instance),
	category: new APICategory(instance),
	format: new APIFormat(instance),
	auth: new APIAuth(instance),
	soulcircusCategory: new APISoulcircusCategory(instance),
	practitioner: new APIPractitioner(instance),
	product: new APIProduct(instance),
};
