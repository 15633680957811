import { Practitioner } from "@cruncho/cruncho-shared-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import { CertificationsSectionItem } from "./CertificationsSectionItem";

interface CertificationsSectionProps {
	practitionerData: Practitioner;
	practitionerId: string;
}

export function CertificationsSection({
	practitionerData,
	practitionerId,
}: CertificationsSectionProps) {
	return (
		<Card>
			<CardHeader title="Certifications & Training section" />
			<CardContent>
				<List>
					{practitionerData.certificationsSection &&
						practitionerData.certificationsSection.map((_, index) => (
							<>
								<CertificationsSectionItem
									certificationsSectionData={practitionerData.certificationsSection!}
									index={index}
									practitionerId={practitionerId}
									key={`certification_${index}`}
								/>
								<Divider
									style={{
										marginTop: 15,
										marginBottom: 20,
									}}
								/>
							</>
						))}
				</List>
			</CardContent>
		</Card>
	);
}
