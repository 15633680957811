import { Destination } from "@cruncho/cruncho-shared-types";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary, {
	AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";

import { useEffect, useState } from "react";
import { useNavigate, useParams, Link as Routerlink } from "react-router-dom";

import { paths } from "../../routes";
import { api } from "../../services/api";
import { CreateDestinationDialog } from "./CreateDestinationDialog";
import { DestinationDetail } from "./DestinationDetail";

const StyledAccordionSummary = (props: AccordionSummaryProps) => (
	<AccordionSummary
		className="flex-row-reverse"
		classes={{
			content: "ml-2 justify-between",
		}}
		expandIcon={<ExpandMoreIcon />}
		{...props}
	/>
);

/**
 * Helper to sort destinations. pass it to a .sort function
 */
const sortDestinations = (cityA: Destination, cityB: Destination) =>
	cityA._id < cityB._id ? -1 : 1;

/**
 * A page displaying the list of available destination and allowing to focus on one
 */
export function DestinationsView() {
	const { enqueueSnackbar } = useSnackbar();

	// id of the current destination
	const { id } = useParams<{ id: string }>();
	const navigate = useNavigate();
	const [showCreateDestinationDialog, setShowCreateDestinationDialog] =
		useState(false);

	// destinations not in a bundle
	const [standaloneDestinations, setStandaloneDestinations] = useState<
		Array<Destination>
	>([]);
	const [bundledDestinations, setBundledDestinations] = useState<
		Record<string, Array<Destination>>
	>({});
	// currently focused destination
	const [currentDestination, setCurrentDestination] =
		useState<Destination | null>(null);

	/**
	 * Callback triggered after updating a destination
	 * Note: only saving the destination in the state. It was already updated in database by children component.
	 * This avoid to have to reload the fullpage everytime a destination is udpated
	 */
	const handleDestinationUpdate = (updatedDestination: Destination) => {
		if (updatedDestination.bundle) {
			const newBundledDestination = {
				...bundledDestinations,
				[updatedDestination.bundle]: bundledDestinations[
					updatedDestination.bundle
				].map((oldDestination) =>
					oldDestination._id === updatedDestination._id
						? updatedDestination
						: oldDestination,
				),
			};

			setBundledDestinations(newBundledDestination);
		} else {
			setStandaloneDestinations(
				standaloneDestinations.map((oldDestination) =>
					oldDestination._id === updatedDestination._id
						? updatedDestination
						: oldDestination,
				),
			);
		}
	};

	/**
	 * Callback triggered after creating a destination
	 */
	const handleDestinationCreation = async (createdDestination: Destination) => {
		try {
			/**
			 * Updating the state directly, no need to refresh the page
			 */
			setStandaloneDestinations(
				standaloneDestinations.concat(createdDestination).sort(sortDestinations),
			);
			enqueueSnackbar(`${createdDestination.name} successfully created`, {
				variant: "success",
			});

			// finally going to the newly created element
			navigate(`${paths.destination}/${createdDestination._id}`);
		} catch (error) {
			console.error(error);
			enqueueSnackbar(JSON.stringify(error, null, 2), {
				variant: "error",
				persist: true,
			});
		}
	};

	// Getting all available destination and separating the bundled from the one without bundles
	useEffect(() => {
		const getDestinations = async () => {
			try {
				const fetchedDestinations: Array<Destination> =
					await api.destination.getAll();

				// first step, getting all destination with a bundle appart from standalone destinations
				const _standaloneDestinations: Array<Destination> = [];
				const _bundledDestinations: Record<string, Array<Destination>> = {};

				fetchedDestinations.forEach((fetchedDestination) => {
					const bundle = fetchedDestination.bundle;

					if (bundle) {
						if (!(bundle in _bundledDestinations)) {
							_bundledDestinations[bundle] = [];
						}

						_bundledDestinations[bundle].push(fetchedDestination);
					} else {
						_standaloneDestinations.push(fetchedDestination);
					}
				});

				// then, need a bit of sorting
				Object.keys(_bundledDestinations).forEach((bundle) =>
					_bundledDestinations[bundle].sort(sortDestinations),
				);

				_standaloneDestinations.sort(sortDestinations);

				// finally saving the destination
				setBundledDestinations(_bundledDestinations);
				setStandaloneDestinations(_standaloneDestinations);
			} catch (error) {
				console.error(error);
			}
		};

		getDestinations();
	}, [navigate]);

	// Loading the destination correspoding to the id in the url
	useEffect(() => {
		if (id) {
			const newDestination = Object.values(bundledDestinations)
				.flat()
				.concat(standaloneDestinations)
				.find((_destination) => _destination._id === id);
			if (newDestination) {
				// destination is a newDestination
				setCurrentDestination(newDestination);
			}
		}
	}, [bundledDestinations, standaloneDestinations, id]);

	return (
		<div className="p-4">
			<Grid container spacing={2}>
				<Grid item xs={3}>
					<Card>
						<CardHeader
							title="Destinations"
							action={
								<IconButton
									title="Create a new destination"
									aria-label="create a new destination"
									color="info"
									size="large"
									onClick={() => setShowCreateDestinationDialog(true)}
								>
									<AddCircleIcon />
								</IconButton>
							}
						/>
						<CardContent>
							<Typography variant="body1" color="textSecondary">
								Without bundles
							</Typography>
							<Accordion>
								<StyledAccordionSummary>
									<Typography>Destinations without bundle</Typography>
									<Chip
										variant="outlined"
										label={standaloneDestinations.length}
										size="small"
									/>
								</StyledAccordionSummary>
								<AccordionDetails>
									<List>
										{standaloneDestinations.map((destination) => (
											<ListItem
												button
												component={Routerlink}
												key={destination._id}
												to={`${paths.destination}/${destination._id}`}
											>
												<ListItemIcon>{destination._id}</ListItemIcon>
											</ListItem>
										))}
									</List>
								</AccordionDetails>
							</Accordion>
						</CardContent>

						<CardContent>
							<Typography variant="body1" color="textSecondary">
								Bundled
							</Typography>
							{Object.entries(bundledDestinations)
								.sort()
								.map(([bundle, destinations]) => (
									<Accordion key={bundle}>
										<StyledAccordionSummary>
											<Typography>{bundle}</Typography>
											<Chip variant="outlined" label={destinations.length} size="small" />
										</StyledAccordionSummary>
										<AccordionDetails>
											<List>
												{destinations.map((destination) => (
													<ListItem
														button
														component={Routerlink}
														key={destination._id}
														to={`${paths.destination}/${destination._id}`}
													>
														<ListItemIcon>{destination._id}</ListItemIcon>
													</ListItem>
												))}
											</List>
										</AccordionDetails>
									</Accordion>
								))}
						</CardContent>
					</Card>
				</Grid>

				<Grid item xs={9}>
					{currentDestination ? (
						<DestinationDetail
							destination={currentDestination}
							handleDestinationUpdate={handleDestinationUpdate}
						/>
					) : (
						<Card variant="outlined">
							<CardHeader title="Welcome to the destination editor" />
							<CardContent>
								<Typography>Here you can edit an existing destination.</Typography>
								<Typography>Please, select a destination in the left panel.</Typography>
							</CardContent>
						</Card>
					)}
				</Grid>
			</Grid>
			<CreateDestinationDialog
				show={showCreateDestinationDialog}
				onCancel={() => setShowCreateDestinationDialog(false)}
				onCreate={async (destination) => {
					await handleDestinationCreation(destination);
					setShowCreateDestinationDialog(false);
				}}
			/>
		</div>
	);
}
