export const paths = {
	root: "/",
	bugReports: "/bugReports",
	category: "/category",
	categoryMapping: "/categoryMapping",
	format: "/format",
	destination: "/destination",
	iframeGenerator: "/iframeGenerator",
	login: "/login",
	widgetGenerator: "/widgetGenerator",
	soulcircus: "/soulcircus",
	practitioner: "/practitioner/:practitionerId",
	product: "/products",
};
