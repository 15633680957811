import { SwitchField, TextField } from "@cruncho/components";
import { Format, formatSchema } from "@cruncho/cruncho-shared-types";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MuiTextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import axios, { AxiosError } from "axios";
import { Form, Formik, prepareDataForValidation } from "formik";
import { useSnackbar } from "notistack";
import { sanitize } from "utils";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { api } from "../../services/api";

type CreateFormatDialogProps = {
	onClose: () => void;
	onSave: (format: Format) => void;
	open: boolean;
};

/**
 * Default configuration to prefill a format
 */
const defaultFormatBase: Format & { displayName: string } = {
	slug: "",
	displayName: "",
	isDefault: false,
	hideOnEM: false,
};

/**
 * A dialog to create a new format
 */
export function CreateFormatDialog({
	onClose,
	onSave,
	open,
}: CreateFormatDialogProps) {
	const { enqueueSnackbar } = useSnackbar();

	const initialValues: Format & { displayName: string } = defaultFormatBase;

	const partialSchema = formatSchema
		.omit({ slug: true })
		// We add a displayName just for the form, so that it get sanitized to a slug.
		// Only the slug is kept in the API
		.merge(z.object({ displayName: z.string() }));

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<Formik
				enableReinitialize
				validationSchema={toFormikValidationSchema(partialSchema)}
				initialValues={initialValues}
				onSubmit={async (values) => {
					const preparedData = prepareDataForValidation(values);

					try {
						const parsedFormat = partialSchema.parse(preparedData);

						// In creation mode, we simplify the process by automatically setting fields related to the displayName
						const sanitizedName = sanitize(parsedFormat.displayName);

						const finalFormat = {
							...parsedFormat,
							slug: sanitizedName,
						};

						try {
							const createdFormat = await api.format.create(finalFormat);

							enqueueSnackbar(`${finalFormat.displayName} created!`, {
								variant: "success",
							});

							// @ts-ignore
							onSave(createdFormat);
						} catch (error: any) {
							console.error(error);
							if (axios.isAxiosError(error) && error.response?.status === 400) {
								enqueueSnackbar("A format with the same slug/name already exists", {
									variant: "warning",
									persist: true,
								});
							} else if (
								axios.isAxiosError(error) &&
								(error as AxiosError<{ message: string }>).response?.data?.message
							) {
								enqueueSnackbar(
									(error as AxiosError<{ message: string }>).response?.data?.message,
									{
										variant: "error",
										persist: true,
									},
								);
							} else {
								enqueueSnackbar(JSON.stringify(error, null, 2), {
									variant: "error",
									persist: true,
								});
							}
						}
					} catch (error) {
						console.error(error);
						enqueueSnackbar(JSON.stringify(error, null, 2), {
							variant: "error",
							persist: true,
						});
					}
				}}
			>
				{({ values, dirty, isSubmitting }) => (
					<Form>
						<DialogTitle>Add a new format</DialogTitle>
						<DialogContent>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography gutterBottom variant="h6">
										General information
									</Typography>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<TextField
												fullWidth
												name="displayName"
												label="Name"
												variant="standard"
											/>
										</Grid>

										<Grid item xs={6}>
											<MuiTextField
												fullWidth
												variant="standard"
												disabled
												label="Slugs will automatically have the value "
												value={sanitize(values.displayName)}
											/>
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12}>
									<Typography gutterBottom variant="h6">
										Customization
									</Typography>
									<SwitchField label="Is a Default format?" name="isDefault" />
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button onClick={onClose} color="primary">
								Cancel
							</Button>
							{dirty && (
								<Button
									type="submit"
									color="secondary"
									variant="outlined"
									disabled={isSubmitting}
								>
									Create
								</Button>
							)}
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
}
