import { PractitionerCard } from "@cruncho/cruncho-shared-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { CopyTextButton } from "views/soulcircus/components/CopyTextButton";
import { DialogTitleWithCloseIcon } from "views/soulcircus/components/DialogTitleWithCloseIcon";
import StyledReadOnlyTextField from "views/soulcircus/components/StyledReadOnlyTextField";

export interface CopyPractitionersEmailProps {
	show: boolean;
	users: PractitionerCard[];
	handleClose: () => void;
}

const CopyPractitionersEmail = ({
	show,
	users,
	handleClose,
}: CopyPractitionersEmailProps) => {
	const emailFormatLine = users.map((user) => user.email).join(";");
	const listFormatLine = users.map((user) => user.email).join("\n");
	return (
		<Dialog open={show} onClose={handleClose}>
			<DialogTitleWithCloseIcon onClose={handleClose}>
				{"Copy practitioners' emails"}
			</DialogTitleWithCloseIcon>
			<DialogContent dividers>
				<Grid container rowSpacing={2}>
					<Grid container item xs={12} alignItems="center">
						<Grid container item xs={12} textAlign="center">
							<Grid item xs={10}>
								<StyledReadOnlyTextField
									label="Click to copy emails in a single line"
									id="emailFormatLineText"
									aria-labelledby="CopyEmailsButton"
									type="text"
									variant="outlined"
									value={emailFormatLine}
								/>
							</Grid>
							<Grid item xs={2}>
								<CopyTextButton text={emailFormatLine} />
							</Grid>
						</Grid>
					</Grid>
					<Grid container item xs={12} alignItems="center">
						<Grid container item xs={12} textAlign="center">
							<Grid item xs={10}>
								<StyledReadOnlyTextField
									id="listFormatLine"
									aria-labelledby="CopyLinesEmailsButton"
									label="Click to copy emails as a list"
									multiline
									value={listFormatLine}
									rows={5}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={2}>
								<CopyTextButton text={listFormatLine} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default CopyPractitionersEmail;
