import {
	StringArrayWithDescriptionField,
	BooleanWithDescriptionField,
} from "@cruncho/components";
import { COUNTRY_CODES } from "@cruncho/cruncho-shared-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

/**
 * Section of fields related to Translations and hooked to formik
 * @returns
 */
export function TranslationsSection() {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h6">Translations</Typography>
			</Grid>

			<Grid item xl={4} lg={6} xs={12}>
				<StringArrayWithDescriptionField
					name="Languages available"
					arrayOptions={[...COUNTRY_CODES]}
					description="This shows the supported language(s) for this guide. The first one is the default language used as a fall-back when the language of a user's navigator is not supported."
					databaseName="features.availableCCs"
				/>
			</Grid>
			<Grid item container spacing={2} xl={4} lg={6} xs={12} className="h-fit">
				<Grid item xs={12}>
					<StringArrayWithDescriptionField
						name="Translation languages"
						arrayOptions={[...COUNTRY_CODES]}
						description="This is an array of the languages translated with DeepL. If this is empty, reviews are translated in all supported languages."
						databaseName="features.translationLanguages"
					/>
				</Grid>
				<Grid item xs={12}>
					<BooleanWithDescriptionField
						name="Fixed default language"
						description="When activated, the default language of the app is always the one chosen. It will not depend on the user's browser language anymore. "
						databaseName="features.disableDefaultLanguageToBrowser"
					/>
				</Grid>
			</Grid>
			<Grid item container spacing={2} xl={4} lg={6} xs={12} className="h-fit">
				<Grid item xs={12}>
					<BooleanWithDescriptionField
						name="Translate reviews with deepL"
						description="When activated, DeepL automatically translates the reviews of your recommendations. You can decide to translate all reviews, or just the one displayed on the preview. This can be activated for any language in your guide."
						databaseName="features.translateWithDeepL"
					/>
				</Grid>
				<Grid item xs={12}>
					<BooleanWithDescriptionField
						name="Translate recommendation title"
						description="When disabled, will not translate the title of the recommendation."
						databaseName="features.translateRecoTitle"
					/>
				</Grid>
			</Grid>
		</Grid>
	);
}
