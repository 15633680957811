import { Practitioner, PractitionerCard } from "@cruncho/cruncho-shared-types";
import { APIBase } from "../APIBase";

/**
 * A class handling the interactions with formats endpoints in the api
 */
export class APIPractitioner extends APIBase {
	async getPractitionerById(practitionerId: string) {
		const response = await this.api.get<Practitioner>(
			`/soulcircus/practitioner/${practitionerId}`,
		);
		return response.data;
	}

	async getAll() {
		const response = await this.api.get<PractitionerCard[]>(
			"/soulcircus/practitioner/practitioners",
		);
		return response.data;
	}

	async updatePractitioner(
		practitionerId: string,
		practitionerData: Partial<Practitioner>,
	) {
		const response = await this.api.put<Practitioner>(
			`/soulcircus/practitioner/${practitionerId}`,
			practitionerData,
		);
		return response.data;
	}

	async deletePractitioner(practitionerId: string) {
		const response = await this.api.delete<string>(
			`/soulcircus/practitioner/${practitionerId}`,
		);

		return response.data;
	}
}
