import { Format } from "@cruncho/cruncho-shared-types";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useEffect, useState } from "react";

import { FormatListItem } from "./FormatListItem";

type FormatListProps = {
	/**
	 * The list of formats to display
	 */
	formats: Format[];
	/**
	 * Triggered when clicking on the button to edit a format
	 */
	onFormatEditSelected: (format?: Format) => void;
};

/**
 * A component displaying a list of formats that can be filtered by name
 *
 */
export function FormatList({ formats, onFormatEditSelected }: FormatListProps) {
	/**
	 * A string used to filter the displayed formats
	 */
	const [searchQuery, setSearchQuery] = useState<string>("");

	/**
	 * The subset of formats kept while filtering
	 */
	const [filteredFormats, setFilteredFormats] = useState<Format[]>([]);

	/**
	 *  When the parent changes the list of formats, sort them alphabetically and apply search quey
	 * Will search by slug and name
	 */
	useEffect(() => {
		if (searchQuery) {
			setFilteredFormats(
				formats.filter((format) => format.slug.includes(searchQuery)),
			);
		} else {
			setFilteredFormats(formats);
		}
	}, [formats, searchQuery]);

	return (
		<Card>
			<CardHeader
				title="Formats"
				action={<Typography>({filteredFormats.length})</Typography>}
			/>
			<CardContent>
				{formats.length > 0 && (
					<TextField
						fullWidth
						label="Search"
						onChange={(event) => {
							// keeping track of the current search query
							setSearchQuery(event.target.value);
						}}
						value={searchQuery}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
							endAdornment: searchQuery && (
								<InputAdornment position="end">
									<IconButton
										onClick={() => setSearchQuery("")}
										size="large"
										aria-label="clear"
									>
										<ClearIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				)}

				{filteredFormats.length > 0 ? (
					<List>
						{filteredFormats.map((format) => (
							<FormatListItem
								key={format.slug}
								format={format}
								onEditSelected={(_format) => onFormatEditSelected(_format)}
							/>
						))}
					</List>
				) : (
					<Box margin={2}>
						<Typography variant="body2" color="textSecondary">
							No formats available
						</Typography>
					</Box>
				)}
			</CardContent>
		</Card>
	);
}
