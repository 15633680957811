import {
	ClipboardCopyButton,
	SelectField,
	SwitchField,
	TextField,
} from "@cruncho/components";
import {
	Category,
	childCategorySchema,
	categorySchema,
	ChildCategory,
} from "@cruncho/cruncho-shared-types";

import ContentPasteIcon from "@mui/icons-material/ClearAll";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import axios, { AxiosError } from "axios";
import { Form, Formik, prepareDataForValidation } from "formik";
import { useSnackbar } from "notistack";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { api } from "../../services/api";
import { CategoryType } from "./types";

type EditCategoryDialogProps<C extends Category | ChildCategory> = {
	category: C;
	categoryType: CategoryType;
	onClose: () => void;
	onSave: (category: C, categoryType: CategoryType) => void;
	open: boolean;
	parentSlugs?: string[];
};

/**
 * A dialog to edit an existing category
 */
export function EditCategoryDialog<C extends Category | ChildCategory>({
	category,
	categoryType,
	onClose,
	onSave,
	open,
	parentSlugs,
}: EditCategoryDialogProps<C>) {
	const { enqueueSnackbar } = useSnackbar();

	// we use a schema compatible with the wanted categoryType
	const schema = (
		categoryType === "L1" ? categorySchema : childCategorySchema
	).omit({ slug: true });

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<Formik
				enableReinitialize
				validationSchema={toFormikValidationSchema(schema)}
				initialValues={category}
				onSubmit={async (values) => {
					const preparedData = prepareDataForValidation(values);
					try {
						const parsedCategory = schema.parse(preparedData);

						// selecting the correspoding api endpoint
						const endpoint =
							categoryType === "L1"
								? api.category.l1
								: categoryType === "L2"
								? api.category.l2
								: api.category.l3;

						try {
							// @ts-ignore Typescript is not able to follow that the parsedCategory is the same as from the input
							const updated = await endpoint.update(category.slug, parsedCategory);

							enqueueSnackbar(`${updated.slug} created!`, {
								variant: "success",
							});

							// @ts-ignore
							onSave(updated, categoryType);
						} catch (error: any) {
							console.error(error);
							if (axios.isAxiosError(error) && error.response?.status === 400) {
								enqueueSnackbar("A category with the same id/name already exists", {
									variant: "warning",
									persist: true,
								});
							} else if (
								axios.isAxiosError(error) &&
								(error as AxiosError<{ message: string }>).response?.data?.message
							) {
								enqueueSnackbar(
									(error as AxiosError<{ message: string }>).response?.data?.message,
									{
										variant: "error",
										persist: true,
									},
								);
							} else {
								enqueueSnackbar(JSON.stringify(error, null, 2), {
									variant: "error",
									persist: true,
								});
							}
						}
					} catch (error) {
						console.error(error);
						enqueueSnackbar(JSON.stringify(error, null, 2), {
							variant: "error",
							persist: true,
						});
					}
				}}
			>
				{({ values, dirty, isSubmitting, setValues }) => (
					<Form>
						<DialogTitle>
							<Grid container>
								<Grid item style={{ flex: 1 }}>
									Edit {categoryType} Category
								</Grid>

								<Grid item>
									<Button
										size="medium"
										color="inherit"
										title="Paste category from the clipboard"
										onClick={async () => {
											try {
												alert("not implemented");

												const pastedObject = JSON.parse(
													await navigator.clipboard.readText(),
												);
												const checkedCategory = schema.parse(pastedObject);

												// @ts-ignore Typescript same
												setValues(checkedCategory);
												enqueueSnackbar("Successfully pasted category from Clipboard", {
													variant: "success",
												});
											} catch (error) {
												console.error(error);
												enqueueSnackbar(JSON.stringify(error, null, 2), {
													variant: "error",
													persist: true,
												});
											}

											navigator.clipboard.writeText(JSON.stringify(values, null, 4));
											enqueueSnackbar("Category Copied to Clipboard", {
												variant: "info",
											});
										}}
									>
										<ContentPasteIcon />
									</Button>

									<ClipboardCopyButton
										data={values}
										title="Copy category"
										onCopyDone={() => enqueueSnackbar("Copied")}
										onClick={() => {
											enqueueSnackbar("Category Copied to Clipboard", {
												variant: "info",
											});
										}}
									/>
								</Grid>
							</Grid>
						</DialogTitle>

						<DialogContent>
							<Grid container spacing={2}>
								{categoryType !== "L1" && parentSlugs && (
									<Grid item xs={6}>
										<Typography gutterBottom variant="h6">
											Parent category
										</Typography>
										<Grid container>
											<Grid item xs={12}>
												<SelectField
													size="small"
													fullWidth
													label="Parent Slug"
													name="parentSlug"
													items={
														parentSlugs.map((option) => ({
															value: option,
															label: option,
														})) ?? []
													}
													variant="outlined"
												/>
											</Grid>
										</Grid>
									</Grid>
								)}

								<Grid item xs={12}>
									<Typography gutterBottom variant="h6">
										General information
									</Typography>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<TextField
												fullWidth
												disabled
												name="slug"
												label="Slug"
												variant="standard"
											/>
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12}>
									<Typography gutterBottom variant="h6">
										Customization
									</Typography>
									<Grid container spacing={2}>
										<Grid item xs={12} md={4}>
											<SwitchField
												label="Is a Default category?"
												name="default"
												color="secondary"
											/>
										</Grid>
										<Grid item xs={12} md={4}>
											<SwitchField
												label="Hide on Event Manager"
												name="hideOnEM"
												color="secondary"
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</DialogContent>

						<DialogActions>
							<Button onClick={onClose} color="primary">
								Cancel
							</Button>
							{dirty && (
								<Button
									type="submit"
									color="secondary"
									variant="outlined"
									disabled={isSubmitting}
								>
									Save
								</Button>
							)}
						</DialogActions>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
}
