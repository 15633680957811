import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { ReactNode, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { paths } from "routes";
import { api } from "services/api";

type ProtectedRouteProps = {
	children: ReactNode;
};

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
	const [loading, setLoading] = useState(true);
	const [connected, setConnected] = useState(false);
	useEffect(() => {
		const fetchStatus = async () => {
			try {
				const status = await api.auth.isConnected();
				setConnected(status);
				setLoading(false);
			} catch (error) {
				setConnected(false);
				setLoading(false);
			}
		};
		fetchStatus();
	}, []);
	if (loading) {
		return (
			<Container>
				<CircularProgress />
			</Container>
		);
	}
	if (connected) {
		return <>{children}</>;
	}

	return <Navigate to={paths.login} />;
}
