import { SoulcircusCategory } from "@cruncho/cruncho-shared-types";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

type SoulcircusCategoryListItemprops = {
	category?: SoulcircusCategory;
	/**
	 * A callback triggered when clicking the edit button
	 */
	onEditSelected: (category?: SoulcircusCategory) => void;
	/**
	 * A callback triggered when clicking the delete button
	 */
	onDeleteSelected: (category?: SoulcircusCategory) => void;
};

/**
 * A component to display one format in a list row
 */
export function SoulcircusCategoryListItem({
	category,
	onEditSelected,
	onDeleteSelected,
}: SoulcircusCategoryListItemprops) {
	return (
		<ListItemButton>
			{category ? (
				<ListItemText
					primary={category.slug}
					secondary={category.type.join(" / ")}
				/>
			) : (
				<ListItemText primary="ALL" />
			)}

			{category && (
				<div className="flex flex-row gap-5">
					<IconButton
						title="Edit this category"
						aria-label="edit this category"
						edge="end"
						onClick={() => {
							onEditSelected(category);
						}}
						size="large"
					>
						<EditIcon />
					</IconButton>
					<IconButton
						title="Delete this category from this type"
						aria-label="delete this category"
						edge="end"
						onClick={() => {
							onDeleteSelected(category);
						}}
						size="large"
					>
						<DeleteIcon />
					</IconButton>
				</div>
			)}
		</ListItemButton>
	);
}
