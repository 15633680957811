import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { paths } from "routes";
import { api } from "services/api";

export default function LoginView() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	useEffect(() => {
		const token = searchParams.get("cruncho_token");
		if (token) {
			api.auth.setToken(token);
			navigate(paths.root);
		}
	}, [searchParams, navigate]);
	const login = async () => {
		await api.auth.connect();
		navigate(paths.root);
	};
	return (
		<Container sx={{ mt: 5 }}>
			<Typography variant="h3">Login Page</Typography>
			<Button sx={{ mt: 3 }} variant="contained" onClick={login}>
				Login with Google
			</Button>
		</Container>
	);
}
