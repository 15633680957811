import { SwitchField, TextField } from "@cruncho/components";
import { PostOption, MailConstellation } from "@cruncho/cruncho-shared-types";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { FieldArray, useField } from "formik";
import { useRef } from "react";

const defaultOption: PostOption[][number] = {
	destination: "",
	label: "my label",
	selected: false,
};

const defaultMail: MailConstellation[][number] = {
	destination: "",
	mail: "",
};

interface OptionItemProps {
	/**
	 * The path of the formik value of the current option
	 */
	name: string;
	/**
	 * Callback triggered when removing the option
	 */
	remove: () => void;
}

/**
 * Component displaying an editable option from postOptions
 */
function OptionItem({ name, remove }: OptionItemProps) {
	return (
		<Grid container spacing={1} alignItems="center">
			<Grid item xs={4}>
				<TextField
					size="small"
					fullWidth
					variant="outlined"
					name={`${name}.destination`}
					label="Destination Name"
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					size="small"
					fullWidth
					variant="outlined"
					name={`${name}.label`}
					label="Destination Label"
				/>
			</Grid>
			<Grid item xs={3} justifyContent="center">
				<SwitchField label="Selected" name={`${name}.selected`} />
			</Grid>

			<Grid item xs={1}>
				<IconButton />
				<Button variant="outlined" color="error" onClick={remove}>
					<DeleteIcon />
				</Button>
			</Grid>
		</Grid>
	);
}

interface MailItemProps {
	/**
	 * The path of the formik value of the current mail
	 */
	name: string;
	/**
	 * Callback triggered when removing the mail
	 */
	remove: () => void;
}

/**
 * Component displaying an editable mail from mailConstellation
 */
function MailItem({ name, remove }: MailItemProps) {
	return (
		<Grid container spacing={1} alignItems="center">
			<Grid item xs={4}>
				<TextField
					size="small"
					fullWidth
					variant="outlined"
					name={`${name}.destination`}
					label="Destination Name"
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					size="small"
					fullWidth
					variant="outlined"
					name={`${name}.mail`}
					label="Mail"
				/>
			</Grid>

			<Grid item xs={1}>
				<IconButton />
				<Button variant="outlined" color="error" onClick={remove}>
					<DeleteIcon />
				</Button>
			</Grid>
		</Grid>
	);
}

/**
 * Component displaying a list of of postOptions allowing creation, edition and deletion
 */
export function PostOptionsCard() {
	// The list of options that are displayed in this component
	// we add an additional key property to be able to pass correct key property
	// A counter takes care of creating uniques keys through the keyCounter ref
	const options =
		useField<Array<PostOption[][number] & { key: number }>>(
			"features.eventManagerFeatures.postOptions",
		)[0].value ?? [];

	const mails =
		useField<Array<PostOption[][number] & { key: number }>>(
			"features.eventManagerFeatures.mailConstellation",
		)[0].value ?? [];

	const isConstellation =
		useField<boolean>("features.eventManagerFeatures.isConstellation")[0].value ??
		false;

	const keyCounter = useRef(0);

	function getNextKey() {
		keyCounter.current += 1;
		return keyCounter.current;
	}

	return (
		<Card>
			<CardHeader
				subheader={
					<Typography variant="h6" gutterBottom>
						Post Options
					</Typography>
				}
			/>

			<CardContent>
				<FieldArray
					name="features.eventManagerFeatures.postOptions"
					render={(arrayHelpers) => (
						<Grid container rowSpacing={2} justifyContent="center">
							{options.map((postOption, index) => (
								<Grid item xs={12} key={postOption.key}>
									<OptionItem
										name={`features.eventManagerFeatures.postOptions[${index}]`}
										remove={() => arrayHelpers.remove(index)}
									/>
									<Divider />
								</Grid>
							))}

							<Grid item>
								<Button
									variant="outlined"
									type="submit"
									onClick={() => {
										// passing an unique key to identify the new option
										arrayHelpers.push({ key: getNextKey(), ...defaultOption });
									}}
								>
									Add new postOption
								</Button>
							</Grid>
							{options.length > 1 ? (
								<Grid item xs={12} className="mb-5">
									Constellation
									<SwitchField
										label="Activate"
										name="features.eventManagerFeatures.isConstellation"
									/>
								</Grid>
							) : (
								<Grid item xs={12} className="mb-5">
									Have at least two post options to activate constellation 2.0
								</Grid>
							)}
						</Grid>
					)}
				/>
				{isConstellation && (
					<>
						<Typography variant="h6" className="mb-5">
							Mail of contact for postOptions (will override mail above)
						</Typography>
						<FieldArray
							name="features.eventManagerFeatures.mailConstellation"
							render={(arrayMailHelpers) => (
								<Grid container xs={12} rowSpacing={2} justifyContent="center">
									<Grid container xs={12} rowSpacing={2}>
										{mails.map((mail, index) => (
											<Grid item xs={12} key={mail.key}>
												<MailItem
													name={`features.eventManagerFeatures.mailConstellation[${index}]`}
													remove={() => arrayMailHelpers.remove(index)}
												/>
												<Divider />
											</Grid>
										))}
										<Grid item>
											<Button
												variant="outlined"
												type="submit"
												onClick={() => {
													// passing an unique key to identify the new mail
													arrayMailHelpers.push({ key: getNextKey(), ...defaultMail });
												}}
											>
												Add new mail
											</Button>
										</Grid>
									</Grid>
								</Grid>
							)}
						/>
					</>
				)}
			</CardContent>
		</Card>
	);
}
