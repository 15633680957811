import { TextField } from "@cruncho/components";
import { FAQSection, FAQSectionSchema } from "@cruncho/cruncho-shared-types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import axios, { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { api } from "services/api";
import { toFormikValidationSchema } from "zod-formik-adapter";

interface FAQSectionItemProps {
	FAQSectionData: FAQSection[];
	index: number;
	practitionerId: string;
}

export function FAQSectionItem({
	FAQSectionData,
	index,
	practitionerId,
}: FAQSectionItemProps) {
	const { enqueueSnackbar } = useSnackbar();

	const initialValues = {
		answer: FAQSectionData[index].answer,
		question: FAQSectionData[index].question,
	};

	return (
		<ListItem>
			<Formik
				initialValues={initialValues}
				onSubmit={async (values, submitProps) => {
					try {
						FAQSectionData[index] = values;
						const toUpdateData = {
							FAQSection: FAQSectionData,
						};
						const res = await api.practitioner.updatePractitioner(
							practitionerId,
							toUpdateData,
						);

						if (res)
							enqueueSnackbar(`${res.firstname} updated!`, {
								variant: "success",
							});
						submitProps.resetForm({ values });
					} catch (error: any) {
						console.error(error);
						if (
							axios.isAxiosError(error) &&
							(error as AxiosError<{ message: string }>).response?.data?.message
						) {
							enqueueSnackbar(
								(error as AxiosError<{ message: string }>).response?.data?.message,
								{
									variant: "error",
									persist: true,
								},
							);
						} else {
							enqueueSnackbar(JSON.stringify(error, null, 2), {
								variant: "error",
								persist: true,
							});
						}
					}
				}}
				validationSchema={toFormikValidationSchema(FAQSectionSchema)}
			>
				{({ dirty, isSubmitting, isValid }) => (
					<Form>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<ListItemText
									primary={
										<Typography fontWeight="bold">{`Question: ${FAQSectionData[index].question}`}</Typography>
									}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									name="question"
									multiline
									label="Question"
									variant="standard"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									name="answer"
									multiline
									label="Answer"
									variant="standard"
								/>
							</Grid>
							<Grid item xs={12}>
								<Button
									disabled={!dirty || isSubmitting || !isValid}
									type="submit"
									variant="contained"
								>
									Save this FAQ
								</Button>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</ListItem>
	);
}
