import { Area, Point } from "@cruncho/cruncho-shared-types";
import { defaultCity } from "@cruncho/utils/helpers";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { useEffect, useState } from "react";
import { AreaEditor } from "./AreaEditor";
import { CreateAreaDialog } from "./CreateAreaDialog";

type AreasEditorProps = {
	/**
	 * A list of areas to edit
	 */
	areas: Area[];
	/**
	 * A default center used to display the map. You can use the destination geo center
	 */
	defaultCenter: Point;
	/**
	 * Callback with the updated areas
	 */
	onAreasChange: (areas: Area[]) => void;
};

/**
 * A component to edit the list of areas of a destination
 */
export function AreasEditor({
	areas,
	defaultCenter,
	onAreasChange,
}: AreasEditorProps) {
	// currently modified area
	const [selectedArea, setSelectedArea] = useState<Area | null>(areas[0]);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [openCreateDialog, setOpenCreateDialog] = useState(false);
	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};

	// update the current areas (e.g. if we select another destination)
	useEffect(() => {
		// trying to stay on the same area, otherwise fallback to the first area available or creating a new area
		setSelectedArea(
			(selectedArea
				? areas.find((_area) => _area.name === selectedArea.name)
				: areas[0]) ?? null,
		);
	}, [areas, selectedArea]);

	const handleCreateArea = (areaName: string) => {
		const newArea: Area = {
			name: areaName,
			city: areas[0]?.city ?? "",
			label: areaName,
			polygon: areas[0]?.polygon ?? {
				// trying to use the same polygon as the first area
				type: "Polygon",
				coordinates: [defaultCity.geography.geoPolygon.coordinates[0]],
			},
		};

		onAreasChange([...areas, newArea]);
		setSelectedArea(newArea);
		setOpenCreateDialog(false);
	};

	/**
	 * Change the current selected area
	 * @param areaName the name of area
	 */
	const handleAreaSelection = (areaName: string) => {
		setSelectedArea(areas.find((area) => area.name === areaName) ?? null);
	};

	/**
	 * Update a modified area or create a new area if it does not alerady exist
	 * @param area The updated content of the area
	 */
	const handleAreaChange = (area: Area) => {
		// creating a new area
		if (!selectedArea) {
			onAreasChange([...areas, area]);
		} else {
			// or updating the current area we just selected
			onAreasChange(
				areas.map((_area) => (_area.name === selectedArea.name ? area : _area)),
			);
		}

		setSelectedArea(area);
	};

	return (
		<Grid container spacing={2} alignItems="flex-end">
			{areas.length > 0 ? (
				<>
					<Grid item xs={2}>
						<FormControl fullWidth>
							<InputLabel>Select the Area</InputLabel>
							<Select
								value={selectedArea?.name ?? ""}
								onChange={(event) => handleAreaSelection(event.target.value as string)}
								variant="standard"
							>
								{areas.map((area) => (
									<MenuItem key={area.name} value={area.name}>
										{area.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item>
						<IconButton
							color="primary"
							onClick={() => {
								setOpenCreateDialog(true);
							}}
							title="Create new Area"
						>
							<AddIcon />
						</IconButton>
					</Grid>

					<Grid item>
						<IconButton
							color="warning"
							onClick={() => {
								setOpenDeleteDialog(true);
							}}
						>
							<DeleteIcon />
						</IconButton>
					</Grid>
				</>
			) : (
				<Grid item>
					<Button
						startIcon={<AddIcon />}
						color="primary"
						onClick={() => {
							setOpenCreateDialog(true);
						}}
					>
						Create new Area
					</Button>
				</Grid>
			)}

			{selectedArea && (
				<>
					<Grid item>
						<AreaEditor
							area={selectedArea}
							defaultCenter={defaultCenter}
							onChange={handleAreaChange}
						/>
					</Grid>

					<Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
						<DialogTitle>Delete area</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Do you realy want to delete {selectedArea.name} ?
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleCloseDeleteDialog} autoFocus>
								Cancel
							</Button>
							<Button
								onClick={() => {
									onAreasChange(
										areas.filter((_area) => _area.name !== selectedArea.name),
									);
									handleCloseDeleteDialog();
								}}
							>
								Agree
							</Button>
						</DialogActions>
					</Dialog>
				</>
			)}
			<CreateAreaDialog
				show={openCreateDialog}
				onCreate={handleCreateArea}
				onCancel={() => setOpenCreateDialog(false)}
			/>
		</Grid>
	);
}
