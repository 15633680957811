import { ChildCategory } from "@cruncho/cruncho-shared-types";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Draggable } from "react-beautiful-dnd";

interface DragableCategoryListItemProps {
	index: number;
	category: ChildCategory;
}

export function DragableCategoryListItem({
	category,
	index,
}: DragableCategoryListItemProps) {
	return (
		<Draggable draggableId={category.slug} index={index}>
			{(provided) => (
				<Grid
					xs={12}
					item
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
				>
					<Paper variant="outlined">
						<Typography color="textSecondary" variant="body2" className="p-2">
							{category.slug}
						</Typography>
					</Paper>
				</Grid>
			)}
		</Draggable>
	);
}
