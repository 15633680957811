import { AvailableBugStatus, BugReport } from "@cruncho/cruncho-shared-types";
import { APIBase } from "./APIBase";

/**
 * A class handling the interactions with bug reports endpoints in the api
 */
export class APIBugReports extends APIBase {
	endpoint = "/bug-report";

	/**
	 * Retrieve the list of all bug reports. If a status argument is given, retrieve
	 * only bug reports with such status
	 *
	 * @param status Filter to keep only {@link BUG_REPORT_STATUS.OPEN open},
	 *               {@link BUG_REPORT_STATUS.CLOSED closed} or {@link BUG_REPORT_STATUS.REJECTED rejected}
	 * @returns List of bug reports in corresponding state
	 */
	async getAll(status?: AvailableBugStatus) {
		const config = status && { params: { status } };
		const response = await this.api.get<Array<BugReport>>(
			`${this.endpoint}`,
			config,
		);
		return response.data.map((bugReport) => ({
			...bugReport,
			createdAt: new Date(bugReport.createdAt),
			updatedAt: new Date(bugReport.updatedAt),
		}));
	}

	/**
	 * Retrieve info about a bug report
	 *
	 * @param id Mongo ID of the bug report to retrive
	 * @returns Full Mongo document
	 */
	async get(id: string) {
		const response = await this.api.get<BugReport>(`${this.endpoint}/${id}`);
		return {
			...response.data,
			createdAt: new Date(response.data.createdAt),
			updatedAt: new Date(response.data.updatedAt),
		};
	}

	/**
	 * Close a bug report
	 *
	 * @param id Mongo ID of the bug report to close/reject
	 * @returns API response status
	 */
	async close(id: string) {
		const response = await this.api.put<BugReport>(
			`${this.endpoint}/${id}/close`,
		);
		return response.status;
	}

	/**
	 * Reject a bug report
	 *
	 * @param id Mongo ID of the bug report to close/reject
	 * @returns API response status
	 */
	async reject(id: string) {
		const response = await this.api.put<BugReport>(
			`${this.endpoint}/${id}/reject`,
		);
		return response.status;
	}
}
