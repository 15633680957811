import { SoulcircusCategory } from "@cruncho/cruncho-shared-types";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useEffect, useState } from "react";

import { SoulcircusCategoryListItem } from "./SoulcircusCategoryListItem";

type SoulcircusCategoryListProps = {
	/**
	 * The list of categories to display
	 */
	categories: SoulcircusCategory[];
	/**
	 * Name of the List
	 */
	name: string;
	/**
	 * Triggered when clicking on the button to edit a category
	 */
	onCategoryEditSelected: (category?: SoulcircusCategory) => void;
	/**
	 * Triggered when clicking on the button to delete a category
	 */
	onCategoryDeleteSelected: (
		category?: SoulcircusCategory,
		type?: string,
	) => void;
};

/**
 * A component displaying a list of categories that can be filtered by name
 *
 */
export function SoulcircusCategoryList({
	categories,
	name,
	onCategoryEditSelected,
	onCategoryDeleteSelected,
}: SoulcircusCategoryListProps) {
	/**
	 * A string used to filter the displayed categories
	 */
	const [searchQuery, setSearchQuery] = useState<string>("");

	/**
	 * The subset of categories kept while filtering
	 */
	const [filteredCategories, setFilteredCategories] = useState<
		SoulcircusCategory[]
	>([]);

	/**
	 *  When the parent changes the list of categories, sort them alphabetically and apply search quey
	 *  Will search by slug and name
	 */
	useEffect(() => {
		if (searchQuery) {
			setFilteredCategories(
				categories.filter((category) => category.slug.includes(searchQuery)),
			);
		} else {
			setFilteredCategories(categories);
		}
	}, [categories, searchQuery]);

	return (
		<Card style={{ maxHeight: "50vh", overflow: "auto" }}>
			<CardHeader
				title={`${name.charAt(0).toUpperCase() + name.slice(1)} categories`}
				action={<Typography>({filteredCategories.length})</Typography>}
				style={{
					position: "sticky",
					top: 0,
					backgroundColor: "white", // Set a background color if needed
					zIndex: 1000, // Adjust the z-index to make sure it's above other elements
				}}
			/>
			<CardContent>
				{categories.length > 0 && (
					<TextField
						fullWidth
						label="Search"
						onChange={(event) => {
							// keeping track of the current search query
							setSearchQuery(event.target.value);
						}}
						value={searchQuery}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
							endAdornment: searchQuery && (
								<InputAdornment position="end">
									<IconButton
										onClick={() => setSearchQuery("")}
										size="large"
										aria-label="clear"
									>
										<ClearIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				)}
				{filteredCategories.length > 0 ? (
					<List>
						{filteredCategories.map((category) => (
							<SoulcircusCategoryListItem
								key={category.slug}
								category={category}
								onEditSelected={(_category) => onCategoryEditSelected(_category)}
								onDeleteSelected={(_category) =>
									onCategoryDeleteSelected(_category, name)
								}
							/>
						))}
					</List>
				) : (
					<Box margin={2}>
						<Typography variant="body2" color="textSecondary">
							No categories available
						</Typography>
					</Box>
				)}
			</CardContent>
		</Card>
	);
}
