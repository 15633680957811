import {
	StringWithDescriptionField,
	BooleanWithDescriptionField,
	L1DependantBooleanWithDescriptionField,
} from "@cruncho/components";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

/**
 * Section of fields related to cruncho features and hooked to formik
 */
export function ClientFeaturesSection() {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h6">Client features</Typography>
			</Grid>

			<Grid item xl={4} lg={6} xs={12}>
				<StringWithDescriptionField
					name="Event Manager URL"
					description="This is the URL of the client's Event Manager."
					databaseName="features.eventManager"
				/>
			</Grid>

			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Hide the top navigation bar"
					description="When activated, the navigation bar at the top of the guide disappears, this to transform it into an Event Calendar format."
					databaseName="features.hideSections"
				/>
			</Grid>

			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Hide the map in the navbar"
					description="When activated, the map in navigation bar at the top of the guide disappears"
					databaseName="features.hideMap"
				/>
			</Grid>

			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Iframe"
					description="When activated, we are in an iFrame environment, therefore changing the way cookies are handled, among other things."
					databaseName="features.iframe"
				/>
			</Grid>

			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Iframe resizing"
					description="When activated, the client has the iframe resizing feature, which requires some changes in the guide."
					databaseName="features.iframeResizing"
				/>
			</Grid>

			<Grid item xl={4} lg={6} xs={12}>
				<BooleanWithDescriptionField
					name="Login from a parent"
					description="This allows the login from a parent window of the guide, through a 'login' message"
					databaseName="features.parentLogin"
				/>
			</Grid>

			<Grid item xl={4} lg={6} xs={12}>
				<L1DependantBooleanWithDescriptionField
					name="Visible vs. hidden sections"
					description="Activated sections are visible on the guide while unactivated sections are hidden."
					databaseName="features.showL1Category"
				/>
			</Grid>

			<Grid item xl={4} lg={6} xs={12}>
				<L1DependantBooleanWithDescriptionField
					name="Visible vs. hidden sections on the Cruncho API"
					description="Activated sections are visible on the Cruncho API `/categories` route while unactivated sections are hidden."
					databaseName="features.showL1CategoryEventsAPI"
				/>
			</Grid>

			<Grid item container spacing={2} xl={4} lg={6} xs={12} className="h-fit">
				<Grid item xs={12}>
					<StringWithDescriptionField
						multiline
						name="Extra Head Tag"
						description="Extra tag to inject in the head of html source"
						databaseName="features.extraHeadTag"
					/>
				</Grid>

				<Grid item xs={12}>
					<StringWithDescriptionField
						multiline
						name="Extra Body Tag"
						description="Extra tag to inject in the body of html source"
						databaseName="features.extraBodyTag"
					/>
				</Grid>
			</Grid>
		</Grid>
	);
}
