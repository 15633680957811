import { Category, ChildCategory } from "@cruncho/cruncho-shared-types";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useEffect, useState } from "react";

import { CategoryListItem } from "./CategoryListItem";
import { CategoryType } from "./types";

type CategoryListProps<C extends Category | ChildCategory> = {
	/**
	 * The list of categories to display
	 */
	categories: Array<C>;
	/**
	 * If true, add a "ALL" row at the top
	 */
	displayRowAll: boolean;
	/**
	 * Triggered when clicking on the button to add a child
	 */
	onCategoryAddSelected?: (parentType: CategoryType, parentSlug: string) => void;
	/**
	 * Triggered when clicking on the button to edit a category
	 */
	onCategoryEditSelected: (type: CategoryType, category?: C) => void;
	/**
	 * Triggered when clicking on the button to delete a category
	 */
	onCategoryDeleteSelected?: (type: CategoryType, slug: string) => void;
	/**
	 * Triggered when clicking on the category row
	 */
	onSelectCategory: (category?: C) => void;
	/**
	 * Curently selected category to highlight
	 */
	selectedCategory?: C;
	/**
	 * The type of the categories of this list (L1, L2, L3) (will also be used to name the list)
	 */
	type: CategoryType;
};

/**
 * A component displaying a list of categories that can be filtered by name
 * It can display a default "ALL" row at the top
 *
 */
export function CategoryList<C extends Category | ChildCategory>({
	categories,
	displayRowAll,
	onCategoryEditSelected,
	onCategoryAddSelected,
	onCategoryDeleteSelected,
	onSelectCategory: onCategorySelected,
	selectedCategory,
	type,
}: CategoryListProps<C>) {
	/**
	 * A string used to filter the displayed categories
	 */
	const [searchQuery, setSearchQuery] = useState<string>("");

	/**
	 * The subset of categories kept while filtering
	 */
	const [filteredCategories, setFilteredCategories] = useState<C[]>([]);

	/**
	 *  When the parent changes the list of categories, sort them alphabetically and apply search quey
	 * Will search by name, slug an displayName
	 */
	useEffect(() => {
		if (searchQuery) {
			setFilteredCategories(
				categories.filter((category) => category.slug.includes(searchQuery)),
			);
		} else {
			setFilteredCategories(categories);
		}
	}, [categories, searchQuery]);

	return (
		<Card>
			<CardHeader
				title={type}
				action={<Typography>({filteredCategories.length})</Typography>}
			/>
			<CardContent>
				{categories.length > 0 && (
					<TextField
						fullWidth
						label="Search"
						onChange={(event) => {
							// keeping track of the current search query
							setSearchQuery(event.target.value);
						}}
						value={searchQuery}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
							endAdornment: searchQuery && (
								<InputAdornment position="end">
									<IconButton
										onClick={() => setSearchQuery("")}
										size="large"
										aria-label="clear"
									>
										<ClearIcon />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				)}

				{filteredCategories.length > 0 ? (
					<List>
						{displayRowAll && (
							<CategoryListItem
								categoryType={type}
								onSelect={onCategorySelected}
								selected={selectedCategory === undefined}
								onEditSelected={() => onCategoryEditSelected(type, undefined)}
							/>
						)}

						{filteredCategories.map((category) => (
							<CategoryListItem
								key={category.slug}
								categoryType={type}
								category={category}
								onAddSelected={
									category &&
									onCategoryAddSelected &&
									(() => onCategoryAddSelected(type, category.slug))
								}
								onSelect={onCategorySelected}
								selected={category.slug === selectedCategory?.slug}
								onEditSelected={(_category) => onCategoryEditSelected(type, _category)}
								onDeleteSelected={
									category &&
									onCategoryDeleteSelected &&
									(() => onCategoryDeleteSelected(type, category.slug))
								}
							/>
						))}
					</List>
				) : (
					<Typography variant="body2" color="textSecondary">
						No categories available
					</Typography>
				)}
			</CardContent>
		</Card>
	);
}
