import { Format } from "@cruncho/cruncho-shared-types";
import { APIBase } from "./APIBase";

/**
 * A class handling the interactions with formats endpoints in the api
 */
export class APIFormat extends APIBase {
	async getAll() {
		const response = await this.api.get<Format[]>("/format");
		return response.data;
	}

	async update(slug: string, format: Format) {
		const response = await this.api.put<Format>(`/format/${slug}`, format);
		return response.data;
	}

	async create(format: Format) {
		const response = await this.api.post<Format>("/format", format);
		return response.data;
	}
}
