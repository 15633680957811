import { TextField } from "@cruncho/components";
import { Practitioner } from "@cruncho/cruncho-shared-types";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import axios, { AxiosError } from "axios";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { api } from "services/api";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

interface AboutSectionProps {
	practitionerData: Practitioner;
	practitionerId: string;
}

export function AboutSection({
	practitionerData,
	practitionerId,
}: AboutSectionProps) {
	const { enqueueSnackbar } = useSnackbar();

	const aboutSchema = z.object({
		description: z.string().min(1),
	});

	const initialValues = {
		description: practitionerData.aboutSection?.description ?? "",
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={async (values, submitProps) => {
				try {
					const toUpdateData = {
						aboutSection: values,
					};
					const res = await api.practitioner.updatePractitioner(
						practitionerId,
						toUpdateData,
					);

					if (res)
						enqueueSnackbar(`${res.firstname} updated!`, {
							variant: "success",
						});

					submitProps.resetForm({ values });
				} catch (error: any) {
					console.error(error);
					if (
						axios.isAxiosError(error) &&
						(error as AxiosError<{ message: string }>).response?.data?.message
					) {
						enqueueSnackbar(
							(error as AxiosError<{ message: string }>).response?.data?.message,
							{
								variant: "error",
								persist: true,
							},
						);
					} else {
						enqueueSnackbar(JSON.stringify(error, null, 2), {
							variant: "error",
							persist: true,
						});
					}
				}
			}}
			validationSchema={toFormikValidationSchema(aboutSchema)}
		>
			{({ dirty, isSubmitting, isValid }) => (
				<Form>
					<Card>
						<CardHeader title="About section" />
						<CardContent>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										fullWidth
										name="description"
										multiline
										label="Description"
										variant="standard"
									/>
								</Grid>
							</Grid>
						</CardContent>
						<CardActions className="flex justify-end">
							<Button
								disabled={!dirty || isSubmitting || !isValid}
								type="submit"
								variant="contained"
							>
								Save about section
							</Button>
						</CardActions>
					</Card>
				</Form>
			)}
		</Formik>
	);
}
