import { Destination } from "@cruncho/cruncho-shared-types";
import Grid from "@mui/material/Grid";
import { FormikDestinationWrapper } from "../FormikDestinationWrapper";
import { ClientFeaturesSection } from "./ClientFeaturesSection";
import { ClientInformationsSection } from "./ClientInformationsSection";
import { CrunchoScoreSection } from "./CrunchoScoreSection";
import { FiltersSection } from "./FiltersSection";
import { GeneralSection } from "./GeneralSection";
import { LandingPageSection } from "./LandingPageSection";
import { RecommendationsSection } from "./RecommendationsSection";
import { ScrapersSection } from "./ScrapersSection";
import { ServiceProvidersSection } from "./ServicesProvidersSection";
import { TranslationsSection } from "./TranslationsSection";

type FeaturesTabProps = {
	/**
	 * The destination to edit
	 */
	destination: Destination;
	/**
	 * Triggered when clicking on save button
	 */
	onSave: (updatedDestination: Destination) => void;
};

/**
 * A tab displaying allowing edition of features related info of a destination
 */
export function FeaturesTab({ onSave, destination }: FeaturesTabProps) {
	return (
		<FormikDestinationWrapper onSave={onSave} destination={destination}>
			<Grid container spacing={2}>
				<Grid item>
					<ClientFeaturesSection />
				</Grid>
				<Grid item xs={12}>
					<ClientInformationsSection />
				</Grid>
				<Grid item xs={12}>
					<CrunchoScoreSection />
				</Grid>
				<Grid item xs={12}>
					<ServiceProvidersSection />
				</Grid>
				<Grid item xs={12}>
					<FiltersSection />
				</Grid>
				<Grid item xs={12}>
					<GeneralSection />
				</Grid>
				<Grid item xs={12}>
					<RecommendationsSection />
				</Grid>
				<Grid item xs={12}>
					<TranslationsSection />
				</Grid>
				<Grid item xs={12}>
					<ScrapersSection />
				</Grid>
				<Grid item xs={12}>
					<LandingPageSection />
				</Grid>
			</Grid>
		</FormikDestinationWrapper>
	);
}
