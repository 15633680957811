import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

import { useState } from "react";

interface CreateAreaDialogProps {
	/**
	 * Triggered when clicking the cancel button
	 */
	onCancel: () => void;
	/**
	 * Triggered when clicking the create button
	 */
	onCreate: (name: string) => void;
	/**
	 * If true, show this dialog
	 */

	show: boolean;
}

/**
 * A simple dialog to create a new Area.
 */
export function CreateAreaDialog({
	onCancel,
	onCreate,
	show,
}: CreateAreaDialogProps) {
	const [name, setName] = useState("");

	return (
		<Dialog open={show} onClose={onCancel} fullWidth>
			<DialogTitle>Create new area</DialogTitle>
			<DialogContent>
				<TextField
					fullWidth
					label="Area name"
					value={name}
					sx={{ mt: 1 }}
					onChange={(event) => {
						setName(event.target.value);
					}}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel} autoFocus aria-label="cancel">
					Cancel
				</Button>
				<Button
					aria-label="create"
					onClick={() => {
						onCreate(name);
					}}
					disabled={name.trim().length === 0} // not allowing empty name
				>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
}
